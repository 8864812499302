import baseServiceNoHeader from "../../services/baseServiceNoHeader.js";
import apiConfig from "../../services/apiConfig";
import sanitizeHtml from "sanitize-html";

const announcementBanner = {
  namespaced: true,
  state: {
    announcementData: [],
    isLoading: false,
  },
  getters: {
    getAnnouncementData: ({ announcementData }) => {
      return announcementData;
    },
    isLoading: ({ isLoading }) => {
      return isLoading;
    },
  },
  mutations: {
    setAnnouncementData(state, payload) {
      const values = JSON.parse(JSON.parse(payload.value));

      if (!Array.isArray(values)) return;

      values.forEach((value) => {
        const startDate = value.startDate ? new Date(value.startDate) : null;
        const endDate = value.endDate ? new Date(value.endDate) : null;
        const today = new Date();
        let isShow = false;
        if (
          startDate !== null &&
          endDate !== null &&
          startDate <= today &&
          endDate >= today
        ) {
          isShow = true;
        }

        state.announcementData.push({
          message: sanitizeHtml(value.message),
          isShow,
          startDate,
          type: (() => {
            switch (value.type) {
              case "important":
                return "is-warning";
              case "alert":
                return "is-danger";
              default:
                return "is-info";
            }
          })(),
        });
      });

      // sort ascending by startDate
      state.announcementData = state.announcementData.sort((a, b) => {
        return a.startDate - b.startDate;
      });

      // max announcement is 3
      state.announcementData = state.announcementData.slice(0, 3);
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getAnnouncementDetail({ commit }, { payload, onError }) {
      return baseServiceNoHeader.post(
        apiConfig.configuration.getEndpointDetail,
        payload,
        (response) => {
          commit("setAnnouncementData", response.data.data);
          commit("setLoading", false);
        },
        (err) => {
          commit("setLoading", false);
          onError(err);
        },
      );
    },
  },
};

export default announcementBanner;
