<template>
  <div
    ref="profileMenu"
    class="header-profile"
    :class="{ 'profile-expanded': isProfileExpanded }"
    @click="toggleProfile"
  >
    <!-- Render toggle button for profile menu inside Header -->
    <button class="header-profile-button">
      <div class="avatar">
        <img :src="imagePlaceholder" alt="lol" />
      </div>
      <span class="name">{{ userName }}</span>
      <o-icon pack="fa" icon="angle-down" />
    </button>
    <!-- Render profile menu -->
    <Transition name="slide-fade">
      <div
        v-show="isProfileExpanded"
        ref="profileExpandMenu"
        class="header-profile-expand"
      >
        <ul class="profile-content">
          <li class="profile-menu">
            <ul class="user">
              <li>
                <a
                  :href="
                    sanitizeUrl(`${rootPath}/account-setting/account-settings`)
                  "
                  rel="noopener noreferrer"
                  >Account Settings</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import imagePlaceholder from "@/assets/img/user-placeholder.svg";
import { useGlobalVariables } from "@/composables/useGlobalVariables";
import { sanitizeUrl } from "@braintree/sanitize-url";

const store = useStore();
const { rootPath } = useGlobalVariables();

const isProfileExpanded = ref(false);
const profileMenu = ref(null);
const profileExpandMenu = ref(null);

const userName = computed(
  () => store.getters["customerSummary/getCustomerName"],
);

function toggleProfile(e) {
  const target = e.target;

  if (
    profileExpandMenu.value !== target &&
    !profileExpandMenu.value.contains(target)
  ) {
    isProfileExpanded.value = !isProfileExpanded.value;
  }
}
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
