import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { publicPath } from "../../vue.config";
import { useGlobalVariables } from "../composables/useGlobalVariables";

const router = createRouter({
  history: createWebHistory(publicPath),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (window.configs.maintenanceMode) {
    if (to.name === "MaintenanceError") {
      next();
    } else {
      window.location.href = `${window.configs.publicUrl}/${window.configs.maintenanceModeRedirect}`;
    }
  } else {
    const { checkAuthentication } = useGlobalVariables();
    const isAuthenticated = checkAuthentication();

    if (!isAuthenticated && to.meta.requiresAuth) {
      window.localStorage.setItem("showLoginMessage", true);
      window.location.href = window.configs.baseUrl;
    } else {
      next();
    }
  }
});

export default router;
