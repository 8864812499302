const domain = window.configs.apiBaseUrl;

const apiConfig = {
  dashboard: {
    soaLastMonth: domain + "/api/trans/v1/soa/last-month",
    soaDeposit: domain + "/api/trans/v1/transaction/deposit-amount",
    collection: domain + "/api/coll/v1/summary",
    lastSoa: domain + "/api/trans/v1/soa/last-soa",
  },
  auth: {
    getUUID: domain + "/public/api/loginhandler/account/auth_uuid",
  },
  configuration: {
    getEndpointDetail: domain + "/api/Configuration/detailsPublic",
  },
  customer: {
    getCustomerList: domain + "/public/api/loginhandler/account/customerlist",
    submitCustomer:
      domain + "/public/api/loginhandler/account/customerselected",
    getEndpointSummary: domain + "/api/cus/v1/account/summary",
  },
};

export default apiConfig;
