<template>
  <div class="header-logout">
    <button class="header-logout-button logout-button" @click="onClick">
      <span class="name"> Log out </span>
    </button>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["click"]);
function onClick() {
  emit("click");
}
</script>

<style lang="scss">
@import "~/@xtremax/xbase-masba/src/styles/variables.scss";
.header-logout {
  &-button {
    height: 100%;
    padding: 10px 20px;
    cursor: pointer;
    color: $gold-1;
    font-family: $font-primary;
    font-size: 16px;
    display: flex;
    align-items: center;
    background-color: $white;
    border: 0;
    width: fit-content;
    transition: 0.2s all linear;

    &:hover {
      background-color: darken($primary-1, 5%);
      color: $white;
    }

    &:focus {
      outline: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}
</style>
