import baseService from "../../services/index";
import apiConfig from "../../services/apiConfig";

const dashboard = {
  namespaced: true,
  state: {
    soaData: {
      closingBalance: 0,
      closingBalanceDeposit: 0,
    },
    collData: {
      outstandingAmount: 0,
      outstandingInvoices: 0,
      overdueInvoices: 0,
    },
    isLoading: false,
  },
  getters: {
    getCollData: ({ collData }) => {
      return collData;
    },
    getSoaData: ({ soaData }) => {
      return soaData;
    },
    isLoading: ({ isLoading }) => {
      return isLoading;
    },
  },
  mutations: {
    setCollData(state, payload) {
      state.collData.outstandingAmount = formatCurrency(
        payload.outstandingAmount,
      );
      state.collData.outstandingInvoices = payload.outstandingInvoices;
      state.collData.overdueInvoices = payload.overdueInvoices;
    },
    setSoaData(state, payload) {
      state.soaData.closingBalance = formatCurrency(payload.closingBalance);
      state.soaData.closingBalanceDeposit = formatCurrency(
        payload.closingBalanceDeposit,
      );
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getSoaData({ commit }, { payload, onError }) {
      commit("setLoading", true);
      return baseService.post(
        apiConfig.dashboard.lastSoa,
        {
          ...payload,
        },
        (response) => {
          commit("setSoaData", response.data.data);
          commit("setLoading", false);
        },
        (err) => {
          commit("setLoading", false);
          onError(err);
        },
      );
    },
    getCollData({ commit }, { payload, onError }) {
      commit("setLoading", true);
      return baseService.post(
        apiConfig.dashboard.collection,
        {
          ...payload,
        },
        (response) => {
          commit("setCollData", response.data.data);
          commit("setLoading", false);
        },
        (err) => {
          commit("setLoading", false);
          onError(err);
        },
      );
    },
  },
};

export default dashboard;

function formatCurrency(value) {
  let formatter = new Intl.NumberFormat("en-SG", {
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}
