<template>
  <ul class="xb-breadcrumb">
    <template v-if="variant === 'normal'">
      <li v-for="(item, index) in breadCrumb" :key="index">
        <a v-if="validateUrl(item.path)" :href="sanitizeUrl(item.path)">
          {{ nameConverter(item.name) }}
        </a>

        <router-link v-else :to="sanitizeUrl(item.path)">
          {{ nameConverter(item.name) }}
        </router-link>
      </li>
    </template>
    <template v-else-if="variant === 'long'">
      <template v-if="isExpand">
        <li
          v-for="(item, index) in breadCrumb"
          :key="index"
          :aria-current="breadCrumb.length === index + 1 ? 'page' : false"
        >
          <a v-if="validateUrl(item.path)" :href="sanitizeUrl(item.path)">{{
            nameConverter(item.name)
          }}</a>

          <router-link v-else :to="item.path">{{
            nameConverter(item.name)
          }}</router-link>
        </li>
      </template>
      <template v-else>
        <li :aria-current="false">
          <a
            v-if="validateUrl(breadCrumb[0].path)"
            :href="sanitizeUrl(breadCrumb[0].path)"
            >{{ nameConverter(breadCrumb[0].name) }}</a
          >

          <router-link v-else :to="breadCrumb[0].path">{{
            nameConverter(breadCrumb[0].name)
          }}</router-link>
        </li>
        <li :aria-current="'page'" @click="handleExpand">
          <p class="is-dotted">...</p>
        </li>
        <li :aria-current="false">
          <a
            v-if="validateUrl(breadCrumb[breadCrumb.length - 2].path)"
            :href="sanitizeUrl(breadCrumb[breadCrumb.length - 2].path)"
            >{{ nameConverter(breadCrumb[breadCrumb.length - 2].name) }}</a
          >
          <router-link v-else :to="breadCrumb[breadCrumb.length - 2].path">{{
            nameConverter(breadCrumb[breadCrumb.length - 2].name)
          }}</router-link>
        </li>
        <li :aria-current="'page'">
          <a
            v-if="validateUrl(breadCrumb[breadCrumb.length - 1].path)"
            :href="sanitizeUrl(breadCrumb[breadCrumb.length - 1].path)"
            >{{ nameConverter(breadCrumb[breadCrumb.length - 1].name) }}</a
          >
          <router-link v-else :to="breadCrumb[breadCrumb.length - 1].path">{{
            nameConverter(breadCrumb[breadCrumb.length - 1].name)
          }}</router-link>
        </li>
      </template>
    </template>
  </ul>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { sanitizeUrl } from "@braintree/sanitize-url";

const isExpand = ref(false);

defineProps({
  breadCrumb: {
    type: Array,
    required: true,
  },
  variant: {
    type: String,
    required: false,
    default: "normal",
  },
});

const nameConverter = computed(() => {
  return (name) => {
    const newName = [];
    const nameCharacters = name ? name.split("") : [];
    if (nameCharacters.length >= 32) {
      nameCharacters.forEach((cart, index) => {
        if (index < 33) {
          newName.push(cart);
        }
      });
      newName.push("...");
    } else {
      return name ? name : "";
    }

    return `${newName.join("")}`;
  };
});

function validateUrl(param) {
  try {
    new URL(param);
  } catch {
    return false;
  }
  return true;
}

function handleExpand() {
  const windowWidth = window.innerWidth;
  if (windowWidth > 768) {
    isExpand.value = true;
  }
}
</script>

<style lang="scss" scoped>
.is-dotted {
  cursor: pointer;
}
</style>
