<template>
  <div>
    <XbaseHeader />
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="false"
      style="z-index: 999"
    />
    <main role="main">
      <div class="wrapper">
        <aside
          id="sidebar-container"
          class="xb-sidebar-internet"
          :class="{
            'on-mobile-open': isMobileView,
            'on-open': isSidebarOpen,
          }"
        >
          <XbaseSidebar />
        </aside>
        <div class="content-container" style="padding: 30px">
          <XbaseTopContent />
          <section>
            <div
              class="xb-dashboard-banner"
              :class="
                mq.current === 'mobile' || mq.current === 'smallMobile'
                  ? 'mobile'
                  : mq.current === 'tablet'
                    ? 'tablet'
                    : ''
              "
            >
              <img
                class="banner-image"
                :src="dashboardImage"
                alt="dashboard image"
              />
              <div class="profile-name">
                <template v-if="mq.current !== 'smallMobile'">
                  <h1>
                    Welcome, <span>{{ user.FullName }}</span>
                  </h1>
                  <h3>
                    {{ subProfileName }}
                  </h3>
                </template>
                <template v-else>
                  <h2>
                    Welcome, <span>{{ user.FullName }}</span>
                  </h2>
                  <h4>
                    {{ subProfileName }}
                  </h4>
                </template>
              </div>
            </div>
            <div class="xb-dashboard-internet">
              <h1 class="dashboard-title">Invoices Summary</h1>
              <div class="grid-row">
                <div
                  :class="`grid-col-${
                    mq.current === 'mobile' || mq.current === 'smallMobile'
                      ? '12'
                      : mq.current === 'desktop' || mq.current === 'tablet'
                        ? '6'
                        : '4'
                  }`"
                >
                  <div class="grid-box invoice-box invoice-box--paid">
                    <h2>Outstanding Amount</h2>
                    <p>
                      $<span>{{ outstandingAmount.wholeNumber }}</span
                      >.{{ outstandingAmount.decimalNumber }}
                    </p>
                  </div>
                </div>
                <div
                  :class="`grid-col-${
                    mq.current === 'mobile' || mq.current === 'smallMobile'
                      ? '12'
                      : mq.current === 'desktop' || mq.current === 'tablet'
                        ? '12'
                        : '8'
                  }`"
                >
                  <div class="grid-row">
                    <div
                      :class="`grid-col-${
                        mq.current === 'mobile' || mq.current === 'smallMobile'
                          ? '12'
                          : '6'
                      }`"
                    >
                      <div
                        class="grid-box invoice-box invoice-box--outstanding"
                      >
                        <h2>Outstanding Invoices</h2>
                        <p>
                          <span>{{ collData.outstandingInvoices }}</span>
                        </p>
                      </div>
                    </div>
                    <div
                      :class="`grid-col-${
                        mq.current === 'mobile' || mq.current === 'smallMobile'
                          ? '12'
                          : '6'
                      }`"
                    >
                      <div class="grid-box invoice-box invoice-box--overdue">
                        <h2>Overdue Invoices</h2>
                        <p>
                          <span>{{ collData.overdueInvoices }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <a
                    :href="sanitizeUrl(`${rootPath}/invoice/invoices`)"
                    class="xb-button button is-primary"
                    type="button"
                    >VIEW INVOICES</a
                  >
                </div>
              </div>
            </div>
            <div class="xb-dashboard-internet">
              <h1 class="dashboard-title">Statement of Accounts Summary</h1>
              <div class="grid-row">
                <div
                  :class="`grid-col-${
                    mq.current === 'mobile' || mq.current === 'smallMobile'
                      ? '12'
                      : mq.current === 'desktop' || mq.current === 'tablet'
                        ? '6'
                        : '4'
                  }`"
                >
                  <div class="grid-box credit-box credit-box--balance">
                    <h2>Last Month's Ending Balance</h2>
                    <p>
                      $<span>{{ endingBalance.wholeNumber }}</span
                      >.{{ endingBalance.decimalNumber }}
                    </p>
                  </div>
                </div>
                <div
                  :class="`grid-col-${
                    mq.current === 'mobile' || mq.current === 'smallMobile'
                      ? '12'
                      : mq.current === 'desktop' || mq.current === 'tablet'
                        ? '6'
                        : '4'
                  }`"
                >
                  <div class="grid-box credit-box credit-box--deposit">
                    <h2>Deposits</h2>
                    <p>
                      $<span>{{ deposits.wholeNumber }}</span
                      >.{{ deposits.decimalNumber }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-4">
                  <a
                    :href="
                      sanitizeUrl(
                        `${rootPath}/transaction/statement-of-accounts`,
                      )
                    "
                    class="xb-button button is-primary"
                    type="button"
                    >VIEW STATEMENT OF ACCOUNTS</a
                  >
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
    <XbaseFooter />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useMq } from "vue3-mq";
import { useErrorHandler } from "@/composables/useErrorHandler";
import { useGlobalVariables } from "@/composables/useGlobalVariables";
import { sanitizeUrl } from "@braintree/sanitize-url";

import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";
import XbaseSidebar from "@/components/shared/xbase-sidebar/XbaseSidebar.vue";
import XbaseFooter from "@/components/shared/xbase-footer/XbaseFooter.vue";
import XbaseTopContent from "@/components/shared/xbase-top-content/XbaseTopContent.vue";
import dashboardImage from "@/assets/img/dashboard-banner.png";

const store = useStore();
const mq = useMq();
const { showErrorModal } = useErrorHandler();
const { rootPath } = useGlobalVariables();

const isMobileView = computed(
  () => mq.current === "mobile" || mq.current === "smallMobile",
);
const isLoading = computed(() => store.getters["dashboard/isLoading"]);
const soaData = computed(() => store.getters["dashboard/getSoaData"]);
const collData = computed(() => store.getters["dashboard/getCollData"]);
const isSidebarOpen = computed(() => store.getters["isSidebarOpen"]);
const user = computed(() => store.getters["auth/user"]);
const customerName = computed(
  () => store.getters["customerSummary/getCustomerName"],
);
const customerIdNumber = computed(
  () => store.getters["customerSummary/getIdentificationNumber"],
);
const loginType = computed(() => {
  return user.value.IsThirdPartyLogin === "True"
    ? window.configs.thirdPartyLabel
    : window.configs.firstPartyLabel;
});
const subProfileName = computed(() => {
  return `${customerName.value}, ${customerIdNumber.value} (${loginType.value})`;
});
const outstandingAmount = computed(() => {
  return {
    wholeNumber: collData.value.outstandingAmount
      ? collData.value.outstandingAmount.split(".")[0]
      : 0,
    decimalNumber: collData.value.outstandingAmount
      ? collData.value.outstandingAmount.split(".")[1]
      : "00",
  };
});
const endingBalance = computed(() => {
  return {
    wholeNumber: soaData.value.closingBalance
      ? soaData.value.closingBalance.split(".")[0]
      : 0,
    decimalNumber: soaData.value.closingBalance
      ? soaData.value.closingBalance.split(".")[1]
      : "00",
  };
});
const deposits = computed(() => {
  return {
    wholeNumber: soaData.value.closingBalanceDeposit
      ? soaData.value.closingBalanceDeposit.split(".")[0]
      : 0,
    decimalNumber: soaData.value.closingBalanceDeposit
      ? soaData.value.closingBalanceDeposit.split(".")[1]
      : "00",
  };
});
const isCustomerLoaded = computed(
  () => store.getters["customerSummaryAction/isCustomerLoaded"],
);

async function loadData() {
  await store.dispatch("auth/getSignedInUser");

  await store.dispatch("dashboard/getSoaData", {
    payload: {},
    onError: (err) => {
      store.commit("dashboard/setLoading", false);
      showErrorModal(err);
    },
  });

  await store.dispatch("dashboard/getCollData", {
    payload: {},
    onError: (err) => {
      store.commit("dashboard/setLoading", false);
      showErrorModal(err);
    },
  });
}

onMounted(() => {
  store.dispatch("auth/startIdleTracker");
});

watch(
  () => isCustomerLoaded.value,
  (value) => {
    if (value === true) {
      loadData();
    }
  },
  { deep: true, immediate: true },
);
</script>

<style lang="scss">
.xb-dashboard-banner {
  position: relative;
  height: 240px;
  background-color: #282829;
  padding: 58px 80px;
  overflow: hidden;
  margin: -40px -60px 30px;

  .banner-image {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    left: calc(582 / 1660 * 100%);
    max-width: none;
  }

  h1 {
    position: relative;
    color: #acacac;
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 1.23em;
    margin: 0;

    .white {
      color: white;
    }
  }

  &.tablet {
    padding: {
      left: 50px;
      right: 50px;
    }

    .banner-image {
      left: auto;
      right: -261px;
    }
  }

  &.mobile {
    height: 130px;
    padding: 30px 26px;

    .banner-image {
      left: auto;
      right: -116px;
    }

    h1 {
      font: {
        size: 1.5rem;
        weight: 700;
      }
      line-height: 1.33em;

      .separator {
        display: block;
      }
    }
  }

  &.tablet,
  &.mobile {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(
        90deg,
        rgb(0 0 0 / 69%) 0%,
        rgb(0 0 0 / 67%) 69%,
        rgba(255, 255, 255, 0) 93%
      );
    }
  }

  @media only screen and (max-width: 1005px) {
    margin: -30px -30px 30px;
  }
}

@import "~/@xtremax/xbase-masba/src/styles/variables.scss";
.xb-dashboard-internet {
  margin-top: 70px;

  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }

  .dashboard-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 30px;
    color: $grey-2;
  }

  .grid-row {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -10px 10px -10px;

    .grid-box {
      height: 100%;
    }
  }

  @for $i from 1 through 12 {
    .grid-col-#{$i} {
      padding: 10px;
      width: calc($i / 12 * 100%);
    }
  }

  .grid-box {
    padding: 30px 30px 48px;
    overflow: hidden;

    @media screen and (max-width: 1023px) {
      padding: 20px 20px 30px;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.2em;
      margin-bottom: 20px;
      color: white;
      opacity: 0.8;

      @media screen and (max-width: 1023px) {
        font-size: 1rem;
        line-height: 1.5em;
        margin-bottom: 10px;
      }
    }

    p {
      font: {
        size: 2.125rem;
        weight: 600;
      }
      line-height: 1.25em;
      margin: 0;
      color: white;
      text-overflow: ellipsis;

      @media screen and (max-width: 1023px) {
        font-size: 1.5rem;
        line-height: 1.33em;
      }

      span {
        font: {
          size: 6rem;
          weight: 900;
        }
        line-height: 0.77em;

        @media screen and (max-width: 1023px) {
          font-size: 3rem;
          line-height: 1.17em;
        }

        @media (1024px <= width <= 1630px) {
          font-size: 3vw;
        }
      }
    }
  }

  .invoice-box {
    position: relative;
    background-color: $secondary-2;
    box-shadow: 8px 8px 0 transparentize($secondary-2, 0.8);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 178px;
      width: 178px;
      opacity: 0.15;
      background-size: auto 100%;

      @media screen and (max-width: 1023px) {
        height: 140px;
        width: 140px;
      }
    }

    &--paid {
      &::after {
        background-image: url("@/assets/vendor/xbase/img/icon-paid.svg");
      }
    }

    &--outstanding {
      &::after {
        background-image: url("@/assets/vendor/xbase/img/icon-outstanding.svg");
      }
    }

    &--overdue {
      &::after {
        background-image: url("@/assets/vendor/xbase/img/icon-overdue.svg");
      }
    }
  }

  .credit-box {
    position: relative;
    background-color: $primary-2;
    box-shadow: 8px 8px 0 $primary-4;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 55px;
      height: 178px;
      width: 178px;
      opacity: 0.15;
      background-size: auto 100%;

      @media screen and (max-width: 1023px) {
        right: 0;
        height: 140px;
        width: 140px;
      }
    }

    &--balance {
      &::after {
        background-image: url("@/assets/vendor/xbase/img/icon-balance.svg");
      }
    }

    &--deposit {
      &::after {
        background-image: url("@/assets/vendor/xbase/img/icon-deposit.svg");
      }
    }
  }

  .xb-button {
    margin-top: 0;

    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.xb-boxes.columns.dashboard-box {
  .dashboard-card.xb-box {
    border: 1px solid #dcdcdc;
    text-align: center;
    color: #37a499;
    margin: 5px;
    padding: 30px;
    min-height: 60px;
    display: block;
    box-shadow: 0 0 20px rgba(38, 128, 235, 0.1);
    background-color: #fff;

    .title {
      text-align: left;
      color: #8d7249;
    }

    .value {
      text-align: right;
    }
  }

  .box-link {
    margin-top: 15px;
    margin-left: 5px;
  }
}

.profile-name {
  position: relative;
  z-index: 2;

  h1,
  h2,
  h3,
  h4 {
    color: #d3d3d3;
  }

  h4 {
    font-size: 12px;
  }

  h2 {
    margin: 0;
  }

  h1,
  h2 {
    span {
      color: #ffffff;
    }
  }
}
</style>
