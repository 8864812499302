<template>
  <div>
    <XbaseHeader />
    <main role="main">
      <div class="wrapper">
        <div class="content-container">
          <h1 class="center has-text-primary has-text-centered">
            User Profile
          </h1>

          <div class="xb-table-block-wrapper">
            <o-loading
              v-model:active="isLoading"
              class="xb-loading"
              :is-full-page="true"
              :can-cancel="false"
              style="z-index: 999"
            />
            <form class="xb-form">
              <o-field class="xb-input" label="Name">
                <o-input :value="name" readonly disabled />
              </o-field>
              <o-field class="xb-input" label="WOG Email">
                <o-input type="email" :value="wogEmail" readonly disabled />
              </o-field>
              <o-field
                grouped
                group-multiline
                class="xb-input"
                label="Cloud ID Email"
              >
                <div class="column is-full is-full-mobile is-paddingless">
                  <o-input
                    type="email"
                    :value="cloudIdEmail"
                    readonly
                    disabled
                  />
                </div>
              </o-field>
              <o-field class="xb-input" label="Office Phone Number">
                <o-input :value="phoneNumber" readonly disabled />
              </o-field>
              <o-field class="xb-input" label="Mobile Number">
                <o-input :value="mobileNumber" readonly disabled />
              </o-field>
              <o-field class="xb-input" label="Active Tenant Code">
                <o-input :value="activeTenantCode" readonly disabled />
              </o-field>
              <o-field class="xb-input" label="Active Role">
                <o-input :value="activeRole" readonly disabled />
              </o-field>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref } from "vue";
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";

const isLoading = ref(false);
const name = ref(null);
const wogEmail = ref(null);
const cloudIdEmail = ref(null);
const phoneNumber = ref(null);
const mobileNumber = ref(null);
const activeTenantCode = ref(null);
const activeRole = ref(null);
</script>
