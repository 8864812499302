import store from "@/store";
import { useGlobalVariables } from "@/composables/useGlobalVariables";

const { isUseMockLogout, checkAuthentication } = useGlobalVariables();

export function useToppan() {
  function getRedirectionURL(uuid, gateway, type) {
    const domain = (() => {
      switch (type) {
        case "login":
          return window.configs.toppanRedirectSPCPPageUrl;
        case "logout":
          return window.configs.toppanRedirectLogout;
        default:
          break;
      }
    })();
    const gatewayQuery = `&gateway=${gateway}`;
    const redirectUriQuery = `&redirect_uri=${window.configs.toppanRedirectUri}/${gateway}/${type}`;
    const eicStateQuery = `&eic_state=${uuid.data.eic_state}`;
    const ecquariaQuery = `&ecquaria_correlationId=${uuid.data.ecquaria_correlationId}`;
    const additionalQuery =
      gatewayQuery + redirectUriQuery + eicStateQuery + ecquariaQuery;
    return domain + additionalQuery;
  }

  async function login(gateway) {
    const isAuthenticated = checkAuthentication();

    if (isAuthenticated) {
      window.location.href = window.configs.portalUrl;
      return;
    }

    try {
      localStorage.setItem("loginGateway", gateway);
      const uuid = await store.dispatch("userProfile/getUserUUID");
      if (uuid) {
        window.localStorage.setItem("eic_state", uuid.data.eic_state);
        window.location.href = getRedirectionURL(uuid, gateway, "login");
      }
    } catch (error) {
      throw Error(error);
    }
  }

  async function logout() {
    try {
      if (isUseMockLogout) {
        window.location.href =
          window.configs.baseUrl + "/api/mockloginlogouttoppan";
      } else {
        const uuid = await store.dispatch("userProfile/getUserUUID");
        if (uuid) {
          const gateway = localStorage.getItem("loginGateway") || "corppass";
          window.location.href = getRedirectionURL(uuid, gateway, "logout");
        }
      }
    } catch (error) {
      throw Error(error);
    }
  }

  return { login, logout };
}
