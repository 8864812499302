<template>
  <header class="header-fixed">
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="false"
      style="z-index: 999"
    />
    <HeaderMasthead />
    <div class="xb-header xb-header-internet">
      <slot name="header-logo">
        <HeaderLogo />
      </slot>

      <slot name="header-menu">
        <!-- Burger menu mobile -->
        <div
          v-if="showProfile && isMobileView && !selectCustomerPage"
          class="header-mobile-menu is-clickable"
          @click="toggleSidebar"
        >
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </slot>

      <slot name="header-profile">
        <!-- Header Profile -->
        <HeaderProfile v-if="showProfile && !isMobileView" />
      </slot>

      <!-- Logout Button -->
      <HeaderLogout v-if="showProfile && !isMobileView" @click="logout" />
    </div>
    <template v-if="isShowAnnouncement">
      <div v-for="(announcement, index) in announcementData" :key="index">
        <CustomMessage
          v-if="announcement.isShow"
          title="Latest Announcement"
          :type="announcement.type"
          :style="isMobileView ? {} : { padding: '0px 20px' }"
          :is-mobile="isMobileView"
          ><span
            class="announcement-message"
            v-html="sanitizeHtml(announcement.message)"
          ></span>
        </CustomMessage>
      </div>
    </template>
  </header>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useMq } from "vue3-mq";
import { useToppan } from "@/composables/useToppan";
import { useErrorHandler } from "@/composables/useErrorHandler";
import sanitizeHtml from "sanitize-html";

import HeaderMasthead from "@/components/shared/xbase-header/HeaderMasthead.vue";
import HeaderLogo from "@/components/shared/xbase-header/HeaderLogo.vue";
import HeaderProfile from "@/components/shared/xbase-header/HeaderProfile.vue";
import HeaderLogout from "@/components/shared/xbase-header/HeaderLogout.vue";
import CustomMessage from "@/components/CustomMessage.vue";

const store = useStore();
const mq = useMq();
const route = useRoute();
const { logout } = useToppan();
const { showErrorModal } = useErrorHandler();

defineProps({
  showProfile: {
    type: Boolean,
    default: true,
  },
});

const isMobileView = computed(
  () => mq.current === "mobile" || mq.current === "smallMobile"
);

const selectCustomerPage = computed(() =>
  route ? route.name === "SelectCustomer" : false
);
const isLoading = computed(() => store.getters["announcementBanner/isLoading"]);
const announcementData = computed(
  () => store.getters["announcementBanner/getAnnouncementData"]
);
const isShowAnnouncement = computed(() => {
  return route
    ? route.name === "Dashboard" ||
        route.name === "MaintenanceError" ||
        route.name === "Announcement"
    : false;
});

function toggleSidebar() {
  const element = document.getElementById("sidebar-container");
  element.classList.toggle("on-open");
  document.body.classList.toggle("sidebar-open");
}
function headerHeightCounter() {
  const headerSelector = document.querySelector("header.header-fixed");
  if (!headerSelector) return;

  new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      const headerHeight = headerSelector.offsetHeight;
      if (document.querySelector("main")) {
        document.querySelector("main").style.paddingTop = `${headerHeight}px`;
      }
    });
  }).observe(headerSelector);
}

onBeforeMount(async () => {
  await store.dispatch("announcementBanner/getAnnouncementDetail", {
    payload: {
      key: "MasBa.Dashboard.Internet.Announcement",
      valueType: "System.String",
    },
    onError: showErrorModal,
  });
});

onMounted(() => {
  headerHeightCounter();
});
</script>

<style lang="scss">
.header-mobile-menu {
  position: relative;
  background: transparent;
  width: 60px;
  height: auto;
  margin-left: -10px;
  .bar {
    position: absolute;
    height: 2px;
    width: 18px;
    background: #8d7249;
    border-radius: 2px;
    top: 50%;
    left: 50%;
    &:first-child {
      -webkit-transform: translate(-50%, calc(-50% - 6px));
      transform: translate(-50%, calc(-50% - 6px));
    }
    &:nth-child(2) {
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    &:nth-child(3) {
      -webkit-transform: translate(-50%, calc(-50% + 6px));
      transform: translate(-50%, calc(-50% + 6px));
    }
  }
}

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

span.announcement-message {
  a {
    color: #705a3a !important;
    text-decoration: underline;
  }
}

.xb-header-internet {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background: #fff;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 768px) {
    margin: 0;
    height: auto;
    justify-content: space-between;
  }

  .header-logo {
    position: inherit;
    top: 0;
    left: 0;
    transform: translate(0);
    align-self: center;
    height: fit-content;
    padding: 16px 0 16px 20px;

    @media only screen and (max-width: 768px) {
      transform: unset;
    }
  }

  .header-logo-internet {
    gap: 2em;
  }
}
</style>
