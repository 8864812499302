import SecurityService from "@xtremax/mas-ba-frameworks/services/securityService";

const AuthManager = window.serviceAuthManager
  ? window.serviceAuthManager
  : new SecurityService(window.configs.idpConfigs, {
      onTokenExpired: () => null,
      activateIdleTracker: true,
    });

export default AuthManager;
