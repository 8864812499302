<template>
  <div class="xb-footer-internet">
    <div class="mas-container">
      <div class="xb-footer-internet--main">
        <div class="xb-footer-internet--left">
          <div class="footer-info">
            <h1>MAS Bill Portal</h1>
          </div>
        </div>
        <div class="xb-footer-internet--right">
          <div class="contact-us">
            <span>
              Contact Us at
              <a
                href="mailto:Bill_Aggregator@mas.gov.sg"
                rel="noopener noreferrer"
                class="ba-email"
                >Bill_Aggregator@mas.gov.sg</a
              >
            </span>
          </div>
        </div>
      </div>
      <div class="xb-footer-internet--legals">
        <div class="xb-footer-internet--links">
          <ul>
            <li>
              <a
                href="https://www.tech.gov.sg/report_vulnerability"
                target="_blank"
                rel="noopener"
              >
                Report Vulnerability
                <i class="fa fa-external-link" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                href="https://www.mas.gov.sg/privacy-statement"
                target="_blank"
                rel="noopener"
                >Privacy Statement</a
              >
            </li>
            <li>
              <a
                href="https://www.mas.gov.sg/terms-of-use"
                target="_blank"
                rel="noopener"
                >Terms of Use</a
              >
            </li>
          </ul>
        </div>
        <div class="xb-footer-internet--copyright">
          <p>
            <span>
              © {{ new Date().getFullYear() }} Government of Singapore.
            </span>
            <span>Last updated on {{ lastUpdated }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const lastUpdated = computed(() => store.getters["footer/getLastUpdated"]);

store.dispatch("footer/fetchLastUpdated", {
  payload: {
    key: "MasBa.Internet.Footer.LastUpdatedPortal",
    valueType: "System.String",
  },
  onError: (err) => {
    console.warn(`Cannot fetch Last Updated: ${err.message}`);
  },
});
</script>

<style lang="scss">
.xb-footer-internet {
  &--right {
    .contact-us {
      a.ba-email {
        margin-left: 0px;
      }
    }
  }

  &--copyright {
    p {
      color: #ffffff;
      margin-bottom: 0;
    }
  }
}
</style>
