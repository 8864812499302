<template>
  <section class="hero is-fullheight is-medium is-bold">
    <o-loading class="xb-loading" :is-full-page="true" :active="isLoading" />

    <div v-if="autoLogout" class="hero-body">
      <div class="container has-text-centered">
        <div class="content">
          <h1 class="has-text-centered">You have been logged out</h1>
          <p class="has-text-centered">
            Your session has expired due to a period of inactivity. <br />Please
            login again if you wish to continue using the portal.
          </p>
        </div>
        <div class="columns">
          <div class="column is-4 is-offset-4 is-mobile">
            <div class="box">
              <div class="columns">
                <div class="column header-logo">
                  <img :src="masLogo" alt="MAS logo" />
                </div>
              </div>
              <button
                class="button is-block is-normal is-primary is-fullwidth"
                style="text-transform: none"
                @click.prevent="signOut"
              >
                Go to Home Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToppan } from "@/composables/useToppan";
import { useErrorHandler } from "@/composables/useErrorHandler";
import masLogo from "@/assets/img/MASLogo.svg";

const route = useRoute();
const store = useStore();
const { logout } = useToppan();
const { showErrorModal } = useErrorHandler();

const autoLogout = ref(false);
const isLoading = ref(false);

async function signOut() {
  isLoading.value = true;
  if (typeof window.localStorage !== "undefined") {
    window.localStorage.clear();
  }

  // If it's from autologout mechanism, add mechanism to loggout the TOPPAN session
  // by redirecting to the TOPPAN logout URL upon clicking the "Go to Home Page" button.
  // Back End will redirect again to this page (/public/logout) without the "autoLogout" query,
  // hence the sign out from IDP will finally be called.
  if (autoLogout.value) {
    try {
      await logout();
    } catch (error) {
      isLoading.value = false;
      showErrorModal(error);
    }
  } else {
    await store.dispatch("auth/signOut");
  }
}

onMounted(() => {
  if (route.query.autoLogout) {
    autoLogout.value = true;
    if (typeof window.localStorage !== "undefined") {
      window.localStorage.clear();
    }
  } else {
    signOut();
  }
});
</script>
