const mutations = {
  scrollTop() {
    (function smoothscroll() {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, 0);
      }
    })();
  },
  toggleSidebar(state) {
    state.isSidebarOpen = !state.isSidebarOpen;
  },
  openSidebarChild(state, obj) {
    const { index, cindex, gcindex } = obj;
    if (cindex === undefined && gcindex === undefined) {
      state.sidebarMenu[index].isActive = !state.sidebarMenu[index].isActive;
    } else if (gcindex === undefined) {
      state.sidebarMenu[index].childs[cindex].isActive =
        !state.sidebarMenu[index].childs[cindex].isActive;
    } else {
      state.sidebarMenu[index].childs[cindex].grandchilds[gcindex].isActive =
        !state.sidebarMenu[index].childs[cindex].grandchilds[gcindex].isActive;
    }
  },
};

export default mutations;
