<template>
  <div class="breadcrumb-row">
    <XbaseBreadcrumb
      v-if="breadcrumbs.length"
      :breadcrumbs-data="breadcrumbs"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import startCase from "lodash/startCase";
import XbaseBreadcrumb from "@/components/shared/xbase-top-content/XbaseBreadcrumb.vue";

const route = useRoute();

const breadcrumbs = computed(() => {
  let breadcrumbsData = [];
  const name = startCase(route.path.split("/").pop());
  if (name !== route.meta.title) {
    breadcrumbsData.push({ name: name, path: route.path });
  }

  //  Set browser title //
  let serviceName = route.meta.title ? `| ${route.meta.title}` : "";

  document.title = `${window.configs.titleBar} ${serviceName}`;
  return breadcrumbsData;
});
</script>
