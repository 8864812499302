<template>
  <Transition name="fade">
    <article v-show="isActive" class="xb-custom-message" :class="type">
      <div class="title">
        <span>{{ title }}</span>
        <button
          v-if="closable && isMobile"
          class="delete"
          @click="close"
        ></button>
      </div>
      <div class="description">
        <span><slot></slot></span>
      </div>
      <button
        v-if="closable && !isMobile"
        class="delete"
        @click="close"
      ></button>
    </article>
  </Transition>
</template>

<script setup>
import { ref, defineProps } from "vue";

defineProps({
  title: {
    type: String,
    default: "Latest Announcement",
  },
  type: {
    type: String,
    default: "",
  },
  closable: {
    type: Boolean,
    default: true,
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
});

const isActive = ref(true);

function close() {
  isActive.value = false;
}
</script>

<style lang="scss">
@import "~/@xtremax/xbase-masba/src/styles/variables.scss";
.xb-custom-message {
  background-color: $info-light;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  align-items: center;
  font-family: $font-primary;
  height: 67px;

  @media screen and (max-width: 768px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
  }

  .title {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    color: $grey-1;
    display: flex;
    margin-right: 18px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px 0;
      justify-content: space-between;
      align-items: center;
      margin-right: 0;
      line-height: 1.5rem;
    }

    span {
      display: flex;
      align-items: center;

      &::before {
        display: inline-block;
        content: "";
        height: 1.25em;
        width: 1.3125em;
        margin: 0.25rem 0.75rem 0.25rem 0;
        background: {
          image: url("@/assets/img/custom-message/message-info.svg");
          size: 100%;
          position: center;
          repeat: no-repeat;
        }
      }
    }

    img {
      height: 20px;
      width: 20px;
      margin-right: 12px;
    }
  }

  .description {
    @media screen and (max-width: 768px) {
      padding: 12px 20px 20px;
    }

    span {
      display: block;
    }
  }

  .delete {
    background-color: transparent;

    justify-self: flex-end;

    &:after {
      background-color: $grey-1;
      height: 90%;
      width: 2px;
    }

    &:before {
      background-color: $grey-1;
      width: 90%;
      height: 2px;
    }
  }

  &.is-danger {
    background-color: $danger-light;

    .title {
      span {
        &::before {
          background-image: url("@/assets/img/custom-message/message-danger.svg");
        }
      }
    }
  }

  &.is-success {
    background-color: $success-light;

    .title {
      span {
        &::before {
          background-image: url("@/assets/img/custom-message/message-success.svg");
        }
      }
    }
  }

  &.is-info {
    background-color: $info-light;

    .title {
      span {
        &::before {
          background-image: url("@/assets/img/custom-message/message-info.svg");
        }
      }
    }
  }

  &.is-warning {
    background-color: $warning-light;

    .title {
      span {
        &::before {
          background-image: url("@/assets/img/custom-message/message-warning.svg");
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
