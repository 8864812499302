export function useGlobalVariables() {
  const rootPath = window.configs.portalUrl;
  const isDevEnvironment =
    window.location.hostname.includes("dev") ||
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("qbillingportal");

  const isUseMockLogout =
    window.location.hostname.includes("dev") ||
    window.location.hostname.includes("localhost");

  function checkAuthentication() {
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    return !!vuex && vuex.auth && vuex.auth.signedIn;
  }

  return { rootPath, isDevEnvironment, checkAuthentication, isUseMockLogout };
}
