<template>
  <header>
    <HeaderMasthead centered show-trigger-dropdown />

    <div class="mas-container">
      <div
        class="header-main d-flex align-items-center is-justify-content-space-between"
      >
        <slot name="header-logo">
          <HeaderLogo />
        </slot>
      </div>
    </div>
    <div
      v-for="(announcement, index) in announcementData"
      :key="index"
      class="message-wrapper"
      :class="announcement.type"
    >
      <template v-if="isMobileView">
        <CustomMessage
          v-if="announcement.isShow"
          title="Latest Announcement"
          :type="announcement.type"
          is-mobile
        >
          <span
            class="announcement-message"
            v-html="sanitizeHtml(announcement.message)"
          ></span>
        </CustomMessage>
      </template>
      <template v-else>
        <div class="mas-container">
          <CustomMessage
            v-if="announcement.isShow"
            title="Latest Announcement"
            :type="announcement.type"
            ><span
              class="announcement-message"
              v-html="sanitizeHtml(announcement.message)"
            ></span>
          </CustomMessage>
        </div>
      </template>
    </div>
  </header>
</template>

<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useMq } from "vue3-mq";
import { useErrorHandler } from "@/composables/useErrorHandler";
import sanitizeHtml from "sanitize-html";

import HeaderMasthead from "@/components/shared/xbase-header/HeaderMasthead.vue";
import HeaderLogo from "@/components/shared/xbase-header/HeaderLogo.vue";
import CustomMessage from "@/components/CustomMessage.vue";

const store = useStore();
const mq = useMq();
const { showErrorModal } = useErrorHandler();

const announcementData = computed(
  () => store.getters["announcementBanner/getAnnouncementData"],
);
const isMobileView = computed(
  () => mq.current === "smallMobile" || mq.current === "mobile",
);

onBeforeMount(async () => {
  await store.dispatch("announcementBanner/getAnnouncementDetail", {
    payload: {
      key: "MasBa.Dashboard.Internet.Announcement",
      valueType: "System.String",
    },
    onError: showErrorModal,
  });
});
</script>

<style lang="scss">
body header {
  .header-secondary {
    @media (max-width: 1199px) {
      height: 100%;
    }
  }
  .header-menu {
    @media (max-width: 1199px) {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: calc(100vh - 60px - 28px);
      overflow: scroll;
      padding-bottom: 100px;

      .mobile-search {
        padding: 3rem 1.5rem 2rem;
        .header-search {
          width: 100%;
          input[type="search"] {
            width: 100%;
            color: #4a4a4a;
          }
        }
      }

      ul.nav-menu.d-flex.align-items-center.justify-content-between {
        flex-direction: column;

        li {
          width: 100%;
          a {
            width: 100%;
            padding: 1rem 1.5rem;
          }
        }
      }
    }
  }
  .header-siteinfo ul.links {
    margin-right: 0px;
  }
  form #header-input-search {
    border: 1px solid #b3b3b3;
    background: #f2f2f2;

    &::placeholder {
      color: #b2b2b2;
    }

    @media (min-width: 1200px) {
      width: 390px;
    }
  }
  .masterhead-container {
    background-color: #f0f0f0;

    .header-masterhead-internet {
      .top {
        a {
          &:hover {
            text-decoration: none;
          }

          &:first-child {
            &:hover {
              color: inherit;
            }
          }
        }
      }
    }
  }
  .message-wrapper {
    .description {
      margin-right: 10px;
    }

    &.is-info {
      background-color: #e1f3f8;
    }
    &.is-danger {
      background-color: #f9dede;
    }
    &.is-warning {
      background-color: #fff1d2;
    }
  }

  span.announcement-message {
    a {
      color: #705a3a !important;
      text-decoration: underline;
    }
  }
  .mas-container:not(.header-masterhead-internet) {
    @media screen and (max-width: 769px) {
      padding-left: 0;
    }
    .header-mobile-menu {
      background: transparent;
      height: auto;
      .bar {
        background: #8d7249;
      }
    }
  }
}
</style>
