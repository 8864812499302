import baseServiceNoHeader from "../../services/baseServiceNoHeader.js";
import apiConfig from "../../services/apiConfig";

const announcementPage = {
  namespaced: true,
  state: {
    announcementData: {
      message: {
        header: "",
        body: "",
      },
      autoRedirection: {
        doAutoRedirection: false,
        url: "",
        intervalRedirection: 5000,
      },
    },
    isLoading: false,
  },
  getters: {
    getAnnouncementData: ({ announcementData }) => {
      return announcementData;
    },
    isLoading: ({ isLoading }) => {
      return isLoading;
    },
  },
  mutations: {
    setAnnouncementData(state, payload) {
      state.announcementData = JSON.parse(JSON.parse(payload.value));
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getAnnouncement({ commit }, { payload, onError }) {
      return baseServiceNoHeader.post(
        apiConfig.configuration.getEndpointDetail,
        payload,
        (response) => {
          commit("setAnnouncementData", response.data.data);
          commit("setLoading", false);
        },
        (err) => {
          commit("setLoading", false);
          onError(err);
        },
      );
    },
  },
};

export default announcementPage;
