<template>
  <div class="header-logo header-logo-internet">
    <a href="https://www.mas.gov.sg/" target="_blank" rel="noopener">
      <img :src="MASLogo" alt="MAS Logo" />
    </a>
    <span class="header-logo-bp">
      <img :src="MASBPLogo" alt="MASBP Logo" />
    </span>
  </div>
</template>

<script setup>
const MASLogo = require("@/assets/img/MASLogo.svg");
const MASBPLogo = require("@/assets/img/MAS-BP-Logo.png");
</script>
