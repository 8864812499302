<template>
  <div>
    <XbaseHeader />
    <main role="main">
      <div class="wrapper">
        <aside
          id="sidebar-container"
          class="xb-sidebar-internet"
          :class="{
            'on-mobile-open': isMobileView,
            'on-open': isSidebarOpen,
          }"
        >
          <XbaseSidebar />
        </aside>
        <div class="content-container">
          <XbaseTopContent />
          <h1 class="underline has-text-primary">{{ route.meta.title }}</h1>
          <div class="tab-wrapper">
            <o-tabs type="left">
              <o-tab-item label="FAQs">
                <FAQ />
              </o-tab-item>
              <o-tab-item label="Contact Us">
                If you have any billing queries, please write to
                <a
                  href="mailto:Bill_Aggregator@mas.gov.sg"
                  target="_blank"
                  class="xb-link"
                  rel="noopener"
                >
                  Bill_Aggregator@mas.gov.sg
                </a>
                or call MAS hotline at +65 6225 5577, Mon-Fri 9am-6pm SGT.
              </o-tab-item>
            </o-tabs>
          </div>
        </div>
      </div>
    </main>
    <XbaseFooter />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useMq } from "vue3-mq";
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";
import XbaseSidebar from "@/components/shared/xbase-sidebar/XbaseSidebar.vue";
import XbaseFooter from "@/components/shared/xbase-footer/XbaseFooter.vue";
import XbaseTopContent from "@/components/shared/xbase-top-content/XbaseTopContent.vue";
import FAQ from "@/pages/ContactUs/FAQ.vue";

const route = useRoute();
const store = useStore();
const mq = useMq();

const isMobileView = computed(
  () => mq.current === "mobile" || mq.current === "smallMobile",
);
const isSidebarOpen = computed(() => store.getters["isSidebarOpen"]);

onMounted(() => {
  store.dispatch("auth/startIdleTracker");
});
</script>

<style lang="scss">
.xb-tabs.o-tabs {
  nav.tabs {
    a {
      font-size: 1.25rem;
    }
  }
  .tab-content {
    max-width: 759px;
  }
}
</style>
