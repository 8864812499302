import service from "@xtremax/mas-ba-frameworks/services";
import AuthManager from "./authManager";
import { publicPath } from "../../vue.config";

const errorHandlingConfigs = {
  errorPageBasePath: publicPath,
  enableMaintenancePage: false,
};

const baseService = service(
  AuthManager,
  window.configs.apiBaseUrl,
  errorHandlingConfigs,
);

export default baseService;
