<template>
  <div>
    <!-- XBase Header Component -->
    <XbaseHeader :show-profile="false" />

    <!-- Main Content of The Page -->
    <main role="main">
      <div class="wrapper">
        <div class="content-container has-text-centered">
          <div class="error-page content">
            <h1 class="has-text-centered">401 Unauthorized</h1>
            <p class="has-text-centered">
              You are not authorized to access this page.
            </p>
            <button
              class="xb-button button is-primary"
              @click="goToLandingPage()"
            >
              GO TO LANDING PAGE
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";
import { useToppan } from "@/composables/useToppan";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

const { logout } = useToppan();
const route = useRoute();

async function goToLandingPage() {
  window.localStorage.clear();
  await logout();
}

onMounted(() => {
  if (route.query.errorId) {
    window.localStorage.clear();
    window.top.location.href = `${window.configs.publicUrl}/unauthorized`;
  } else {
    setTimeout(() => {
      goToLandingPage();
    }, 8000); // 8 seconds delay time (use milliseconds)
  }
});
</script>

<style lang="scss">
.error-page.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
