<template>
  <div class="dialog modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card animation-content">
      <section
        class="modal-card-body modal-card-body-internet is-titleless is-flex"
      >
        <div class="media">
          <div class="media-left">
            <span
              class="icon is-large"
              :class="{ 'has-text-danger': isDanger, 'has-text-info': isInfo }"
            >
              <o-icon
                :pack="isDanger ? 'mdi' : 'fa'"
                :icon="isDanger ? 'alert-circle' : 'info-circle'"
              />
            </span>
          </div>
          <div class="media-content">
            <p></p>
            <div>
              <div class="xb-dialog-error-wrapper">
                <h2 class="is-danger heading-internet">{{ errorCode }}</h2>
                <span>
                  <em>{{ description }}</em>
                </span>
              </div>
            </div>
            <p></p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot modal-card-foot-internet">
        <button
          class="button button-internet"
          :class="{
            'is-danger': type === 'danger',
            'is-info': type === 'info',
          }"
          @click="clickHandler"
        >
          {{ confirmText }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";

const emit = defineEmits(["close", "confirm"]);

const props = defineProps({
  errorCode: {
    type: String,
    default: "",
  },
  referenceId: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  confirmText: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "danger",
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const isDanger = computed(() => props.type === "danger");
const isInfo = computed(() => props.type === "info");

function clickHandler() {
  emit("close");
  emit("confirm");
}
</script>

<style lang="scss">
.xb-modal.modal {
  .modal-card-body.is-flex {
    .media {
      flex-direction: column;
      align-items: center;

      .media-left {
        .icon.is-large {
          position: unset;
          transform: translate(0);
          width: 80px;
          height: 80px;

          i.fa::before {
            font-size: 80px;
          }
        }
      }

      .media-content {
        .xb-dialog-error-wrapper {
          h2 {
            font-size: 34px;
            text-transform: capitalize;
            color: #4a4a4a;

            &.heading-internet {
              margin-bottom: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .dialog.modal.is-active {
    & + span.modal-close {
      display: none;
    }

    .modal-card-body.modal-card-body-internet {
      padding: 50px 50px 0;
    }

    .modal-card-foot.modal-card-foot-internet {
      padding: 0px 50px 50px;
      padding-bottom: 50px;
    }
  }

  .modal-background {
    background-color: #4a4a4a80;
  }

  .modal-card.animation-content {
    background-color: white;
  }

  .button.button-internet.is-info {
    background-color: #8d7249;
    color: white !important;
    border-color: #8d7249 !important;
  }
}
</style>
