<template>
  <div class="xb-breadcrumb-container-internet">
    <nav aria-label="breadcrumb" class="xb-breadcrumb">
      <XbaseBreadcrumbContent
        :bread-crumb="breadCrumb"
        :variant="breadCrumb.length <= 4 ? 'normal' : 'long'"
      />
    </nav>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useRoute } from "vue-router";
import XbaseBreadcrumbContent from "@/components/shared/xbase-top-content/XbaseBreadcrumbContent.vue";

const route = useRoute();

const props = defineProps({
  breadcrumbsData: {
    type: Array,
    default: () => [],
  },
});

const breadCrumb = computed(() => {
  if (props.breadcrumbsData) {
    return props.breadcrumbsData;
  } else {
    let breadcrumbs = [];
    let path = "";

    const fullPathArr = route.fullPath.split("/");

    // Remove path based on parameter
    // for example, if route is { fullPath: "/path/:id", params: {id: "some-id"} }
    // breadcrumb should not display `Path / Some Id` but `Path`
    const paramsLength = Object.keys(route.params).length;
    if (paramsLength > 0) fullPathArr.splice(-1, paramsLength);

    // remove first array which is an empty string//
    fullPathArr.shift();

    // if route contain 'view' or 'edit' string
    // which indicate its on a detail page then
    // breadcrumb will be constructed with the
    // following logic (0-2)
    // 0. Find index/position of the string
    let idxView = fullPathArr.indexOf("view");
    let idxEdit = fullPathArr.indexOf("edit");

    // 1. If found, the value will be > 0
    //    then remove all following strings
    //    e.g. params contain in the path
    if (idxView > 0) {
      fullPathArr.splice(idxView, fullPathArr.length - idxView);
    }

    if (idxEdit > 0) {
      fullPathArr.splice(idxEdit, fullPathArr.length - idxEdit);
    }

    // 2. Get and append params manually.
    //    What will be shown on the last xbase breadcrumb
    //    is the current page string, it should be using title as
    //    its key in route params object.
    if ((idxView > 0 || idxEdit > 0) && route.params["title"]) {
      fullPathArr.push(route.params["title"]);
    }

    // Transform fullPathArray into a breadcrumb collection
    // the name will be transformed to title case strings
    fullPathArr.forEach((element) => {
      const urlQueryRemoved = element.split(/[?#]/)[0]; // To remove query params
      let arrName = urlQueryRemoved.split("-");

      const name = arrName
        .map((elm) => elm.charAt(0).toUpperCase() + elm.slice(1))
        .join(" ");

      path = path + "/" + element;

      breadcrumbs.push({ name: name, path: path });
    });

    return breadcrumbs;
  }
});
</script>
