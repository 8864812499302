const header = {
  namespaced: true,
  state: {
    serviceName: "XBase Service",
    folderName: "",
    headerPath: "",
    userName: null,
    userEmail: "",
    permission: {},
  },
  getters: {
    getUserName: ({ userName }) => {
      return userName;
    },
  },
  mutations: {
    setServiceName(state, serviceName) {
      state.serviceName = serviceName;
    },
    setFolderName(state, folderName) {
      state.folderName = folderName;
    },
    setHeaderPath(state, path) {
      state.headerPath = path;
    },
    setPermission(state, payload) {
      state.permission = payload;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setUserEmail(state, userEmail) {
      state.userEmail = userEmail;
    },
  },
};

export default header;
