<template>
  <section>
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="true"
    />
    <!-- Router View for child component such as create page, edit page, etc -->
    <RouterView />
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { RouterView, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useErrorHandler } from "@/composables/useErrorHandler";
import { useGlobalVariables } from "@/composables/useGlobalVariables";

const route = useRoute();
const store = useStore();
const { showErrorModal, showInfoModal } = useErrorHandler();
const { checkAuthentication } = useGlobalVariables();

const isLoading = ref(false);

const customerIdNumber = computed(() => {
  return store.getters["customerSummary/getIdentificationNumber"];
});
const customerLoginType = computed(() => {
  return store.getters["customerSummary/getLoginType"];
});
const customerHaveContactPerson = computed(() => {
  return store.getters["customerSummary/getIsHaveContactPerson"];
});
const isRedirectToAddPrimaryContact = computed(() => {
  return (
    customerLoginType.value === "corppass" &&
    !customerHaveContactPerson.value &&
    !window.configs.excludedRoutesForNoContactUser.includes(route.name)
  );
});

async function checkCustomerSummary() {
  if (!customerIdNumber.value) {
    isLoading.value = true;
    await store.dispatch("customerSummaryAction/getCustomerSummary", {
      payload: {},
      onError: (err) => {
        isLoading.value = false;
        showErrorModal(err);
      },
    });
    isLoading.value = false;
  }
}

onMounted(async () => {
  const isAuthenticated = checkAuthentication();
  if (isAuthenticated) {
    await checkCustomerSummary();

    if (isRedirectToAddPrimaryContact.value) {
      showInfoModal({
        errorCode: "There is no Primary Contact",
        description:
          "Please provide your contact person's details in the account settings page to receive notifications and perform activities in the portal.",
        confirmText: "Account Settings",
        onConfirm: () => {
          // redirect to add primary contact person for corpass account
          window.location = `${window.configs.portalUrl}/account-setting/account-settings/manage-contact-information`;
        },
      });
    }
  }
});
</script>
