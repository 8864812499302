import baseServiceNoHeader from "../../services/baseServiceNoHeader.js";
import apiConfig from "../../services/apiConfig";

const selectCustomer = {
  namespaced: true,
  state: {
    selectCustomerData: {
      userInfo: {
        fullName: null,
        nricFin: null,
        uen: null,
      },
      ownCustomer: {
        customerName: null,
        identificationNumber: null,
        identificationType: null,
        roles: [],
      },
      tpCustomer: [],
    },
    isLoading: false,
  },
  getters: {
    getSelectCustomerData: ({ selectCustomerData }) => {
      return selectCustomerData;
    },
    isLoading: ({ isLoading }) => {
      return isLoading;
    },
  },
  mutations: {
    setSelectCustomerData(state, payload) {
      state.selectCustomerData = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    getSelectCustomerData({ commit }, { payload }) {
      commit("setLoading", true);
      return baseServiceNoHeader.post(
        apiConfig.customer.getCustomerList,
        payload,
        (response) => {
          commit("setSelectCustomerData", response.data.data);
          commit("setLoading", false);
        },
        () => {
          // eslint-disable-next-line no-undef
          const destinationPath = window.location.origin;
          window.location.replace(destinationPath);
        },
      );
    },
    submitSelectCustomer({ commit }, { payload }) {
      commit("setLoading", true);
      return baseServiceNoHeader.post(
        apiConfig.customer.submitCustomer,
        payload,
        (response) => {
          const path = response.data.data.redirectUrl;
          // eslint-disable-next-line no-undef
          const destinationPath = window.location.origin + path;
          window.location.replace(destinationPath);
        },
        (error) => {
          const path = error.response.data.data.redirectUrl;
          // eslint-disable-next-line no-undef
          const destinationPath = window.location.origin + path;
          window.location.replace(destinationPath);
        },
      );
    },
  },
};

export default selectCustomer;
