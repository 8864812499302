<template>
  <div>
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="true"
      :can-cancel="false"
      style="z-index: 999"
    ></o-loading>
    <!-- MAS Header Component -->
    <MASHeader />
    <!-- Main Content of The Page -->
    <main role="main">
      <template v-if="isExact">
        <MASBanner />
        <div class="home-content">
          <div class="mas-container">
            <div class="home-content--tabs">
              <div class="d-flex flex-wrap">
                <a
                  class="home-content--tab"
                  :class="activeTab === 'billing' ? 'active' : ''"
                  @click.prevent="activeTab = 'billing'"
                >
                  <h1>Billing Dashboard</h1>
                  <p>
                    Here you can quickly get an overview of your billing
                    matters.
                  </p></a
                >
                <div
                  v-if="mq.current === 'mobile'"
                  class="home-content--content-inner"
                  :class="activeTab === 'billing' ? 'active' : ''"
                >
                  <picture v-if="activeTab === 'billing'">
                    <source
                      media="(max-width: 767px)"
                      :srcset="imgUrl.billing.mobile"
                    />
                    <source
                      media="(max-width: 1199px)"
                      :srcset="imgUrl.billing.tablet"
                    />
                    <img :src="imgUrl.billing.main" alt="billing-dashboard" />
                  </picture>
                </div>
                <a
                  class="home-content--tab"
                  :class="activeTab === 'consolidated' ? 'active' : ''"
                  @click.prevent="activeTab = 'consolidated'"
                >
                  <h1>Consolidated Invoices</h1>
                  <p>One invoice, each month. View it here in the portal.</p></a
                >
                <div
                  v-if="mq.current == 'mobile'"
                  class="home-content--content-inner"
                  :class="activeTab === 'consolidated' ? 'active' : ''"
                >
                  <picture v-if="activeTab === 'consolidated'">
                    <source
                      media="(max-width: 767px)"
                      :srcset="imgUrl.consolidated.mobile"
                    />
                    <source
                      media="(max-width: 1199px)"
                      :srcset="imgUrl.consolidated.tablet"
                    />
                    <img
                      :src="imgUrl.consolidated.main"
                      alt="consolidated-invoice"
                    />
                  </picture>
                </div>
                <a
                  class="home-content--tab"
                  :class="activeTab === 'selfService' ? 'active' : ''"
                  @click.prevent="activeTab = 'selfService'"
                >
                  <h1>Self Service Tools</h1>
                  <p>
                    Decide who will receive notifications and view auto
                    deduction information.
                  </p></a
                >
                <div
                  v-if="mq.current === 'mobile'"
                  class="home-content--content-inner"
                  :class="activeTab === 'selfService' ? 'active' : ''"
                >
                  <picture v-if="activeTab === 'selfService'">
                    <source
                      media="(max-width: 767px)"
                      :srcset="imgUrl.selfService.mobile"
                    />
                    <source
                      media="(max-width: 1199px)"
                      :srcset="imgUrl.selfService.tablet"
                    />
                    <img
                      :src="imgUrl.selfService.main"
                      alt="self-service-tools"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div
              class="home-content--content"
              :class="mq.current === 'mobile' ? 'isMobile' : ''"
            >
              <picture v-if="activeTab === 'billing'">
                <source
                  media="(max-width: 767px)"
                  :srcset="imgUrl.billing.mobile"
                />
                <source
                  media="(max-width: 1199px)"
                  :srcset="imgUrl.billing.tablet"
                />
                <img :src="imgUrl.billing.main" alt="billing-dashboard" />
              </picture>
              <picture v-if="activeTab === 'consolidated'">
                <source
                  media="(max-width: 767px)"
                  :srcset="imgUrl.consolidated.mobile"
                />
                <source
                  media="(max-width: 1199px)"
                  :srcset="imgUrl.consolidated.tablet"
                />
                <img
                  :src="imgUrl.consolidated.main"
                  alt="consolidated-invoice"
                />
              </picture>
              <picture v-if="activeTab === 'selfService'">
                <source
                  media="(max-width: 767px)"
                  :srcset="imgUrl.selfService.mobile"
                />
                <source
                  media="(max-width: 1199px)"
                  :srcset="imgUrl.selfService.tablet"
                />
                <img :src="imgUrl.selfService.main" alt="self-service-tools" />
              </picture>
            </div>
          </div>
        </div>
      </template>
      <router-view v-else />
    </main>
    <XbaseFooter />
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useMq } from "vue3-mq";
import sanitize from "sanitize-html";
import { useErrorHandler } from "@/composables/useErrorHandler";
import { useToppan } from "@/composables/useToppan";

import MASHeader from "@/components/shared/mas-header/MASHeader.vue";
import MASBanner from "@/components/shared/mas-banner/MASBanner.vue";
import XbaseFooter from "@/components/shared/xbase-footer/XbaseFooter.vue";

const route = useRoute();
const store = useStore();
const mq = useMq();
const { showErrorModal } = useErrorHandler();
const { logout } = useToppan();

const isLoading = ref(false);
const activeTab = ref("billing");
const imgUrl = ref({
  billing: {
    main: "assets/img/billing-dashboard.png",
    tablet: "assets/img/billing-dashboard-tablet.png",
    mobile: "assets/img/billing-dashboard-mobile.png",
  },
  consolidated: {
    main: "assets/img/consolidated-invoices.png",
    tablet: "assets/img/consolidated-invoices-tablet.png",
    mobile: "assets/img/consolidated-invoices-mobile.png",
  },
  selfService: {
    main: "assets/img/self-service-tools.png",
    tablet: "assets/img/self-service-tools-tablet.png",
    mobile: "assets/img/self-service-tools-mobile.png",
  },
});

const errorMessage = computed(() => store.getters["common/getErrorMessage"]);
const isExact = computed(() => route.name === "LandingPage");

async function fetchErrorMessage({ valueType, key }) {
  return store.dispatch("common/fetchErrorMessage", {
    payload: {
      valueType,
      key,
    },
    onError: showErrorModal,
  });
}

async function showErrorMessage() {
  const errorKey = route.query.errorkey;
  const showLoginMessage = JSON.parse(
    window.localStorage.getItem("showLoginMessage"),
  );

  const errorTitle =
    errorKey === "MasBa.Internet.LoginHandler.Unauthorized.Login"
      ? window.configs.errorCode.unauthorized.title
      : window.configs.errorCode.technicalIssue.title;

  if (errorKey) {
    // Redirection due to a technical issue from the server.
    // Handled by the server.
    await fetchErrorMessage({ valueType: "System.String", key: errorKey });
    showErrorModal(
      {
        status: errorTitle,
        message: sanitize(decodeURIComponent(errorMessage.value)),
      },
      {},
      {
        onConfirm: () => {
          if (errorKey === "MasBa.Internet.LoginHandler.Unauthorized.Login") {
            logoutToppan();
          }
        },
      },
    );
  } else if (showLoginMessage) {
    // Redirection due to a user has not logged in and is unauthorized
    // to access the dashboard or other modules.
    // Handled by the UI.
    await fetchErrorMessage({
      valueType: "System.String",
      key: "MasBa.Internet.LoginHandler.Unauthorized.Login",
    });
    showErrorModal({
      status: window.configs.errorCode.unauthorized.title,
      message: sanitize(decodeURIComponent(errorMessage.value)),
    });
    window.localStorage.removeItem("showLoginMessage");
  }
}

async function logoutToppan() {
  try {
    isLoading.value = true;
    await logout();
  } catch (error) {
    isLoading.value = false;
    showErrorModal(error);
  }
}

onBeforeMount(() => {
  let head = document.getElementsByTagName("head")[0];
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = "assets/css/style.2.0.0.css";
  link.media = "all";
  head.appendChild(link);
});

onMounted(() => {
  showErrorMessage();
});
</script>

<style lang="scss">
.home-content--tab:hover,
.home-content--tab.active {
  h1,
  p {
    color: white;
  }
}
.fix-content {
  padding: 70px 0;
  text-align: center;
  width: 100%;
}
.home-content--content-inner {
  display: none;
  padding: 70px 0;
  text-align: center;
  width: 100%;
}
.home-content--content-inner.active {
  display: block;
}
.home-content--content.isMobile {
  display: none;
}
.home-content--content {
  display: block;
}
</style>
