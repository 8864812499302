import LandingPage from "../pages/LandingPage/LandingPage.vue";
import Portal from "../pages/Portal/Portal.vue";
import Public from "../pages/Public/Public.vue";
import AccessDenied from "../pages/AccessDenied/AccessDenied.vue";
import Unauthorized from "../pages/Unauthorized/Unauthorized.vue";
import NotFound from "../pages/NotFound/NotFound.vue";
import MaintenanceError from "../pages/Maintenance/MaintenanceErrorHeader.vue";
import ContactUs from "../pages/ContactUs/ContactUs.vue";
import Dashboard from "../pages/Dashboard/Dashboard.vue";
import OidcCallback from "../pages/OidcCallback/OidcCallback.vue";
import SilentRenewCallback from "../pages/OidcCallback/SilentRenewCallback.vue";
import InitOIDC from "../pages/OidcCallback/InitOIDC.vue";
import Login from "../pages/Login/Login.vue";
import Logout from "../pages/Logout/Logout.vue";
import UserProfile from "../pages/UserProfile/UserProfile.vue";
import SelectCustomer from "../pages/SelectCustomer/SelectCustomer.vue";
import AnnouncementPage from "../pages/AnnouncementPage/AnnouncementPage.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: {},
  },
  {
    path: "/portal",
    name: "Portal",
    component: Portal,
    redirect: "portal/dashboard",
    children: [
      {
        path: "login",
        name: "PortalLogin",
        component: Login,
        meta: {
          title: "Please wait, redirecting..",
        },
      },
      {
        path: "oidccallback:_(.*)",
        name: "PortalOidcCallback",
        component: OidcCallback,
        meta: {
          title: "Please wait, redirecting...",
        },
      },
      {
        path: "silentrenewcallback",
        name: "PortalSilentRenewCallback",
        component: SilentRenewCallback,
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: true, title: "Dashboard" },
      },
      {
        path: "contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: { requiresAuth: true, title: "Contact Us" },
      },
    ],
  },
  {
    path: "/public",
    name: "Public",
    component: Public,
    children: [
      {
        path: "access-denied",
        name: "AccessDenied",
        component: AccessDenied,
        meta: {},
      },
      {
        path: "announcement",
        name: "Announcement",
        component: AnnouncementPage,
        meta: {},
      },
      {
        path: "scheduled-maintenance",
        name: "MaintenanceError",
        component: MaintenanceError,
        meta: {},
      },
      {
        path: "unauthorized",
        name: "Unauthorized",
        component: Unauthorized,
        meta: {},
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
        meta: {
          title: "Please wait, redirecting...",
        },
      },
      {
        path: "login/select-customer",
        name: "SelectCustomer",
        component: SelectCustomer,
        meta: {},
      },
      {
        path: "not-found",
        name: "NotFound",
        component: NotFound,
        meta: {},
      },
      {
        path: "ssologin",
        name: "SSOLogin",
        meta: {},
      },
    ],
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {},
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Please wait, redirecting..",
    },
  },
  {
    path: "/initOIDC:_(.*)",
    name: "InitOIDC",
    component: InitOIDC,
    meta: {
      title: "Please wait, redirecting...",
    },
  },
  {
    path: "/oidccallback:_(.*)",
    name: "OidcCallback",
    redirect: "portal/oidccallback",
    component: OidcCallback,
    meta: {
      title: "Please wait, redirecting...",
    },
  },
  {
    path: "/silentrenewcallback",
    name: "SilentRenewCallback",
    redirect: "portal/silentrenewcallback",
    component: SilentRenewCallback,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: NotFound,
    meta: {},
  },
];

export default routes;
