<template>
  <div>
    <!-- XBase Header Component -->
    <XbaseHeader :show-profile="false" />

    <!-- Main Content of The Page -->
    <main role="main">
      <div class="wrapper">
        <div class="content-container has-text-centered">
          <div class="error-page content">
            <h1 class="has-text-centered">404 Not Found</h1>
            <p class="has-text-centered">
              Sorry, the page you requested cannot be found!
            </p>
            <button
              class="xb-button button is-primary"
              @click="goToLandingPage()"
            >
              GO TO LANDING PAGE
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";

function goToLandingPage() {
  window.location.href = window.configs.baseUrl;
}
</script>

<style lang="scss">
.error-page.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
