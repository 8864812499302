<template>
  <nav class="xb-sidebar-list sidebar-fixed">
    <!-- Sidebar Item Level 1 -->
    <ul>
      <template v-for="item in sidebarMenu">
        <li
          v-if="item.isShow"
          :key="item.id"
          class="sidebar-item"
          :class="{ 'active-item-level-1': item.isActive }"
        >
          <!-- Router link -->
          <a
            :href="sanitizeUrl(`${rootPath}${item.path}`)"
            rel="noopener noreferrer"
          >
            {{ item.name }}
          </a>
        </li>
      </template>
      <template v-if="isMobileView">
        <div class="divider"></div>
        <li class="sidebar-item mt-4">
          <a class="name">{{ userName }}</a>
        </li>
        <li class="sidebar-item">
          <a class="name logout-button" rel="noreferrer" @click="logout"
            >Logout</a
          >
        </li>
      </template>
    </ul>
  </nav>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useMq } from "vue3-mq";
import { useGlobalVariables } from "@/composables/useGlobalVariables";
import { useToppan } from "@/composables/useToppan";
import { sanitizeUrl } from "@braintree/sanitize-url";

const store = useStore();
const route = useRoute();
const mq = useMq();
const { rootPath } = useGlobalVariables();
const { logout } = useToppan();

const sidebarMenu = computed(() => store.getters["getSidebarMenu"](route));
const userName = computed(
  () => store.getters["customerSummary/getCustomerName"],
);
const isMobileView = computed(
  () => mq.current === "mobile" || mq.current === "smallMobile",
);

function sidebarHeightCounter() {
  const sidebarFixed = document.querySelector(".xb-sidebar-list.sidebar-fixed");

  if (!sidebarFixed) return;

  const headerHeight = () =>
    document.querySelector("header.header-fixed")?.offsetHeight || 0;

  setOffsetHeight(sidebarFixed, headerHeight());

  const calculateSidebarHeight = () => {
    const bodyScrollHeight = document.body.scrollHeight;
    const footerScrollHeight = document.querySelector(
      ".xb-footer-internet",
    ).scrollHeight;
    const scrollPosition =
      window.innerHeight -
      0 +
      (document.body.scrollTop || document.documentElement.scrollTop);
    const footerHeight = scrollPosition - bodyScrollHeight;
    const visibleFooterHeight = footerScrollHeight + footerHeight;
    const sidebarHeight = headerHeight() + visibleFooterHeight;

    if (visibleFooterHeight > 0) {
      setOffsetHeight(sidebarFixed, sidebarHeight);
    } else {
      setOffsetHeight(sidebarFixed, headerHeight());
    }
  };

  window.addEventListener("scroll", calculateSidebarHeight);
  window.addEventListener("resize", calculateSidebarHeight);
}

function setOffsetHeight(sidebarFixed, value) {
  sidebarFixed.style.cssText = `--offset-height: ${value}px`;
}

onMounted(() => {
  sidebarHeightCounter();
});
</script>

<style lang="scss">
@import "~/@xtremax/xbase-masba/src/styles/variables.scss";
.xb-sidebar-internet {
  position: relative;
  display: table-cell;
  vertical-align: top;
  overflow: hidden;
  background: $secondary-4;
  color: $grey-1;
  z-index: 1;
  height: auto;
  width: 0px;
  transition: width 0.2s ease-in-out;

  &.on-open {
    width: 260px;
    transition: width 0.2s ease-in-out;

    .xb-sidebar-list {
      width: 260px;
    }

    .sidebar-item {
      a {
        display: flex;

        .icon {
          + span {
            display: block;
          }
        }
      }

      > ul {
        padding-left: 10px;
      }

      .sidebar-item-toggle {
        right: 0;

        left: auto;
      }
    }
  }

  .xb-sidebar-list {
    width: 0px;
    // transition: width 0.2s ease-in-out;

    > ul {
      width: 260px;
      padding: 0;
    }

    ul {
      width: 260px;

      .divider {
        border-top: 2px solid #d3d3d3;
      }
    }
  }

  //   styling with icon
  &.has-icon {
    width: 55px;

    .xb-sidebar-list {
      width: 55px;
    }

    &.on-open {
      width: 260px;
      //   transition: width 0.2s ease-in-out;

      .xb-sidebar-list {
        width: 260px;
      }

      .sidebar-item {
        .sidebar-item-toggle {
          right: 0;

          left: auto;
        }
      }
    }

    .sidebar-item {
      a {
        padding: 14px 40px 14px 18px;
      }

      .sidebar-item-toggle {
        position: absolute;
        // right: 0;
        width: 56px;
        height: 56px;
        cursor: pointer;
        top: 0;
        left: -25px;

        i {
          position: absolute;
          color: $secondary-2;
          right: 20px;
          font-size: 0.75em;
          top: 20px;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
}

.sidebar-item {
  position: relative;

  &:hover {
    background: $grey-4;
  }

  a {
    display: flex;
    padding: 17px 30px 17px 20px;
    color: inherit;
    font-size: 16px;
    line-height: 22px;

    .icon {
      margin-right: 16px;

      + span {
        display: none;
      }
    }
  }

  .name {
    color: $gold-1;
  }

  //   styling custom icon
  .xb {
    font-size: 1.33333333em;
    line-height: 0.75em;
    vertical-align: -15%;
  }

  .sidebar-item-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    width: 46px;
    height: 46px;
    cursor: pointer;
    top: 5px;
    // left: -25px;

    i {
      color: $secondary-2;
      font: {
        size: 0.8em;
        weight: 300;
      }
      transition: all 0.5s ease-in-out;
    }
  }

  > ul {
    height: 0;
    padding-left: 0px;
    transition: height 0.5s ease-in-out;

    > li {
      display: none;
    }
  }

  &.active-item-level-1,
  &.active-item-level-2,
  &.active-item-level-3 {
    background: $grey-4;

    > .sidebar-item-toggle {
      i {
        transform: rotateZ(90deg);
      }
    }

    > ul {
      height: auto;
      transition: height 0.5s ease-in-out;

      > li {
        display: block;
      }
    }
  }

  .router-link-active,
  .router-link-active.router-link-exact-active {
    color: inherit;
    background: $grey-4;
    border-bottom: 0;
  }
}

.sidebar-open {
  position: fixed;
  overflow: hidden;
}

// ===========
// ------------------------------------------------------------
// Please refer to Bulma breakpoints
// https://bulma.io/documentation/modifiers/responsive-helpers/
// -------------------------------------------------------------

@media only screen and (min-width: 1408px) {
  // FullHD 1408px and above
}

@media only screen and (max-width: 1407px) {
  // Widescreen Between 1216px and 1407px
}

@media only screen and (min-width: 769px) and (max-width: 1215px) {
  // Desktop Between 769px and 1215px
  .xb-sidebar-header {
    width: 180px;

    .xb-sidebar-header-title {
      span {
        font-size: 16px;
        left: 15px;
      }
    }

    .xb-sidebar-header-button {
      width: 40px;
    }
  }

  .xb-sidebar-internet {
    .xb-sidebar-list {
      width: 40px;

      ul {
        width: 40px;

        .sidebar-item a {
          padding: 14px 5px 14px 10px;
          font-size: 14px;
        }
      }
    }

    &.has-icon {
      width: 40px;

      .xb-sidebar-list {
        width: 40px;

        ul {
          width: 40px;
        }

        ul .sidebar-item a {
          padding: 14px 5px 14px 10px;
          font-size: 14px;
        }

        ul .sidebar-item .o-icon {
          margin-right: 0;
        }
      }
    }

    &.xb-sidebar-internet.on-open {
      width: 180px;

      .xb-sidebar-list {
        width: 180px;

        ul {
          width: 180px;
        }

        ul .sidebar-item a {
          padding: 14px 35px 14px 10px;
          font-size: 14px;
        }
      }

      &.xb-sidebar-internet.has-icon {
        width: 180px;

        .xb-sidebar-list {
          width: 180px;
        }

        .xb-sidebar-list ul .sidebar-item .o-icon {
          margin-right: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1215px) {
  // Desktop Between 1024px and 1215px
  .xb-sidebar-internet.has-icon .sidebar-item .sidebar-item-toggle {
    width: 35px;
  }

  .sidebar-item {
    .sidebar-item-toggle {
      width: 35px;
      left: -15px;

      i {
        right: 20px;
        // font-size: 0.5em;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  // Tablet Between 769px and 1023px
}

@media only screen and (max-width: 768px) {
  // Mobile Up to 768px

  .xb-sidebar-internet {
    position: fixed;
    display: block;
    width: 100%;
    left: 0;
    height: 0;
    transition:
      height 0.5s ease-in-out,
      width 0s ease-in-out;
    top: 150px;
    z-index: 5;

    &.has-icon {
      &.on-mobile-open {
        width: 100%;

        .xb-sidebar-list {
          width: 100%;
        }
      }
    }

    &.on-mobile-open {
      &.on-open {
        height: 0;
        transition: height 0.5s ease-in-out;
      }

      height: calc(100vh - 113px);
      //   transition: height 0.5s ease-in-out;
      position: absolute;
      top: 113px;
      z-index: 10;
      width: 100%;
      overflow-y: scroll;

      .xb-sidebar-list {
        padding-top: 0;
        width: 100%;
        // transition: width 0s ease-in-out;

        ul {
          width: 100%;
        }

        > ul {
          padding: 0;
          min-height: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .xb-sidebar-internet {
    nav.xb-sidebar-list {
      &.sidebar-fixed {
        --offset-height: 0px;
        max-height: calc(100vh - var(--offset-height));
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        direction: rtl;

        & > * {
          direction: ltr;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $secondary-4;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $secondary-2;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
