<template>
  <section class="hero is-fullheight is-medium is-bold">
    <o-loading class="xb-loading" :is-full-page="true" :active="isLoading" />
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const route = useRoute();
const store = useStore();

const isLoading = ref(false);

async function signIn() {
  isLoading.value = true;
  const { UserID } = route.query;
  const destinationPath = window.configs.portalUrl;
  await store.dispatch("auth/signIn", {
    destinationPath,
    query: { UserID: UserID },
  });
}

onMounted(() => {
  signIn();
});
</script>
