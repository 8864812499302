<template>
  <section>
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="true"
      :can-cancel="false"
      style="z-index: 999"
    ></o-loading>
    <!-- Router View for child component such as create page, edit page, etc -->
    <router-view />
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useToppan } from "@/composables/useToppan";

const route = useRoute();
const { login } = useToppan();

const isLoading = ref(false);

onMounted(async () => {
  if (route.name === "SSOLogin") {
    isLoading.value = true;
    await login("corppass");
  }
});
</script>
