<template>
  <div>
    <!-- Main Content of The Page -->
    <main role="main">
      <!-- Overlaying Buefy Loading Component -->
      <o-loading
        v-model:active="isLoading"
        class="xb-loading"
        :is-full-page="false"
        :can-cancel="false"
        style="z-index: 20"
      />
    </main>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useGlobalVariables } from "@/composables/useGlobalVariables";

const store = useStore();
const { checkAuthentication } = useGlobalVariables();

const isLoading = ref(true);

onMounted(() => {
  const isAuthenticated = checkAuthentication();
  if (!isAuthenticated) {
    store.dispatch("auth/signInRedirectCallback");
  }
});
</script>
