import { createStore } from "vuex";
import customerSummary from "@xtremax/mas-ba-frameworks/vuex/customerSummary.js";

import header from "./modules/header.js";
import footer from "./modules/footer.js";
import userProfile from "./modules/userProfile.js";
import sidebar from "./modules/sidebar/index.js";
import dashboard from "./modules/dashboard.js";
import selectCustomer from "./modules/selectCustomer.js";
import common from "./modules/common.js";
import announcementBanner from "./modules/announcementBanner.js";
import customerSummaryAction from "./modules/customerSummaryAction.js";
import authModule from "@xtremax/mas-ba-frameworks/vuex/auth";
import AuthManager from "../services/authManager";
import announcementPage from "./modules/announcementPage.js";

const store = createStore({
  modules: {
    header,
    footer,
    userProfile,
    sidebar,
    dashboard,
    customerSummary,
    selectCustomer,
    common,
    announcementBanner,
    announcementPage,
    customerSummaryAction,
    auth: authModule(AuthManager, window.configs.apiBaseUrl),
  },
});
export default store;
