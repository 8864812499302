import { useOrugaProgrammatic } from "@/composables/useOrugaProgrammatic";
import ErrorHandlerModal from "@/components/ErrorHandlerModal.vue";

export function useErrorHandler() {
  const { OModal } = useOrugaProgrammatic();

  function showErrorModal(error = {}, errorConfigs = {}, options = {}) {
    let modalProps = {
      ...options,
      errorCode: error.status || error.code,
      referenceId: "",
      description: error.message,
      confirmText: "CLOSE",
    };

    if (
      error.message === errorConfigs.name ||
      error.message === errorConfigs.message
    ) {
      modalProps = {
        ...modalProps,
        errorCode: errorConfigs.name,
        description: errorConfigs.message,
      };
    }

    OModal({ component: ErrorHandlerModal, props: modalProps });
  }

  function showInfoModal(options) {
    let modalProps = {
      ...options,
      type: "info",
    };
    OModal({ component: ErrorHandlerModal, props: modalProps });
  }

  return { showErrorModal, showInfoModal };
}
