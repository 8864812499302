import baseService from "../../services/index.js";
import apiConfig from "../../services/apiConfig";

const customerSummaryAction = {
  namespaced: true,
  state: {
    isCustomerLoaded: false,
  },
  getters: {
    isCustomerLoaded: ({ isCustomerLoaded }) => {
      return isCustomerLoaded;
    },
  },
  mutations: {
    setIsCustomerLoaded(state, payload) {
      state.isCustomerLoaded = payload;
    },
  },
  actions: {
    getCustomerSummary({ commit }, { onError }) {
      return baseService.get(
        apiConfig.customer.getEndpointSummary,
        (response) => {
          if (response) {
            commit("setIsCustomerLoaded", true);
            if (response.data.status === 200) {
              commit("customerSummary/setCustomerSummary", response.data.data, {
                root: true,
              });
            }
          } else {
            commit("setIsCustomerLoaded", false);
          }
        },
        (error) => {
          if (error.response.status === 401) {
            commit("setIsCustomerLoaded", false);
          } else {
            commit("setIsCustomerLoaded", true);
          }
          onError(error);
        },
      );
    },
  },
};

export default customerSummaryAction;
