import baseServiceNoHeader from "../../services/baseServiceNoHeader";
import apiConfig from "../../services/apiConfig";

const userProfile = {
  namespaced: true,
  state: {
    signedIn: true,
  },
  mutations: {
    setSignedIn(state, signedIn) {
      state.signedIn = signedIn;
    },
  },
  actions: {
    getUserUUID() {
      return baseServiceNoHeader.get(
        apiConfig.auth.getUUID,
        (response) => {
          return response;
        },
        (error) => {
          const path = error.response.data.redirectUrl;
          // eslint-disable-next-line no-undef
          const destinationPath = window.location.origin + path;
          window.location.replace(destinationPath);
        },
      );
    },
  },
};

export default userProfile;
