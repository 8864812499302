import { isProxy, toRaw } from "vue";
import mutations from "../sidebar/mutations.js";

export default {
  state: {
    isSidebarOpen: true,
    serviceName: "Internet",
    sidebarMenu: [
      {
        id: 0,
        name: "Dashboard",
        path: "/dashboard",
        isActive: false,
        isShow: true,
      },
      {
        id: 1,
        name: "Invoices",
        path: "/invoice/invoices",
        isActive: false,
        isShow: true,
      },
      {
        id: 2,
        name: "Credit Notes",
        path: "/invoice/credit-notes",
        isActive: false,
        isShow: true,
      },
      {
        id: 3,
        name: "Statement of Accounts",
        path: "/transaction/statement-of-accounts",
        isActive: false,
        isShow: true,
      },
      {
        id: 4,
        name: "Transaction History",
        path: "/transaction/transaction-history",
        isActive: false,
        isShow: true,
      },
      {
        id: 5,
        name: "Account Settings",
        path: "/account-setting/account-settings",
        isActive: false,
        isShow: true,
      },
      {
        id: 6,
        name: "Contact Us",
        path: "/contact-us",
        isActive: false,
        isShow: true,
      },
    ],
  },
  getters: {
    getSidebarMenu({ sidebarMenu }) {
      return (currentRoute) => {
        const currentRouteData = isProxy(currentRoute)
          ? toRaw(currentRoute)
          : currentRoute;
        const sidebarMenuData = isProxy(sidebarMenu)
          ? toRaw(sidebarMenu)
          : sidebarMenu;

        sidebarMenuData.forEach((item) => {
          // Reset active menu before assigning a new one
          item.isActive = false;
          if (currentRouteData.path.includes(`/portal${item.path}`)) {
            item.isActive = true;
          }
        });

        return sidebarMenuData;
      };
    },
    isSidebarOpen: ({ isSidebarOpen }) => isSidebarOpen,
    serviceName: ({ serviceName }) => serviceName,
  },
  mutations,
};
