<template>
  <section class="hero is-fullheight is-medium is-bold">
    <o-loading class="xb-loading" :is-full-page="true" :active="isLoading" />
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const isLoading = ref(false);

async function signIn() {
  isLoading.value = true;
  const destinationPath = window.configs.portalUrl;
  try {
    await store.dispatch("auth/renewToken");
    window.location.replace(destinationPath);
  } catch (error) {
    const UserID = store.getters["auth/user"].sub;
    store.dispatch("auth/signIn", {
      destinationPath,
      query: { UserID: UserID },
    });
  }
}

onMounted(() => {
  signIn();
});
</script>

<style>
.is-modified {
  padding: 0 0.75rem;
}

.column.header-logo img {
  max-height: 120px;
}
</style>
