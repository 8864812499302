import { useProgrammatic } from "@oruga-ui/oruga-next";

const { oruga } = useProgrammatic();

export function useOrugaProgrammatic() {
  function ONotification({
    duration = 2000,
    position = "top",
    ...otherParams
  }) {
    oruga.notification.open({
      ...otherParams,
      duration,
      position,
    });
  }

  function OModal(param) {
    oruga.modal.open(param);
  }

  return { ONotification, OModal };
}
