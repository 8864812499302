<template>
  <section>
    <o-collapse
      v-for="(faq, index) in faqData"
      :key="index"
      v-model:open="faq.isOpen"
      class="xb-collapse card faq-collapse"
    >
      <template #trigger="props">
        <div class="card-header" :class="{ 'header-open': props.open }">
          <p class="card-header-title faq-title">{{ faq.title }}</p>
          <a class="card-header-icon">
            <o-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <div
            v-for="(qna, idx) in faq.questions"
            :key="idx"
            class="wrap-faq-content"
          >
            <div class="columns question">
              <div class="column">{{ qna.question }}</div>
            </div>
            <div class="columns answer">
              <div class="column">
                <span v-html="sanitizeHtml(qna.answer)"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </o-collapse>
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import sanitizeHtml from "sanitize-html";

const route = useRoute();
const faqData = ref([
  {
    title: "General Information",
    questions: [
      {
        question: "What is MAS Bill Portal?",
        answer:
          "The MAS Bill Portal is an online portal for you to access to retrieve invoices, statement of accounts and access to other information such as details of charges and transaction history.",
      },
      {
        question:
          "I have a new colleague, how can he/she get access to the MAS Bill Portal?",
        answer:
          "Access to MAS Bill Portal is managed via CorpPass. You may wish to enquire with the CorpPass Administrator in your company.",
      },
      {
        question: "Who else in my company has access to MAS Bill Portal?",
        answer:
          "Access to MAS Bill Portal is managed via CorpPass. You may wish to enquire with the CorpPass Administrator in your company.",
      },
    ],
    isOpen: false,
  },
  {
    title: "Email Notifications related",
    questions: [
      {
        question: "Who would receive email notifications?",
        answer:
          "Anyone whose email address is included in Contact Information under Account Settings. Please note that up to 5 secondary contacts can be included. If your organisation requires more than 5 persons to be notified, you may wish to indicate group email address in the Contact Information.",
      },
      {
        question: "What email notifications would I receive?",
        answer:
          "Notifications are sent to inform about status of GIRO applications, invoices, credit notes and automated deductions.",
      },
      {
        question: "Why didn’t I receive email notifications?",
        answer:
          "Please check if your contact information has been included in Email Notifications under the Account Settings Module.  Please also verify the accuracy of your email address. If your contact information has been provided under Account settings and you have not received any emails, you might want to:<br /><br /><ol><li>Check your Spam/Junk mail to see if the mail was redirected to that folder.</li><li>Check with your IT department to confirm if the mail was blocked.</li></ol>",
      },
      {
        question:
          "Previously I have received my invoice attachment and SOA attachment via email. Why are the invoice attachment and SOA attachments removed from the email?",
        answer:
          "We have launched the website called MAS Bill Portal in Q3 2023 which allows you to view various billing information online such as invoices, SOAs and credit notes.",
      },
    ],
    isOpen: false,
  },
  {
    title: "Invoice related",
    questions: [
      {
        question:
          "Download Annex button is greyed out, why can’t I download the Annex?",
        answer:
          "There is no Annex to download as your Invoice did not contain fee item with further details.",
      },
      {
        question:
          "I have no access to the portal, how can I download the annexes?",
        answer:
          "Please contact the Corppass Administrator within your company and seek for access to this MAS Bill Portal.",
      },
    ],
    isOpen: false,
  },
  {
    title: "Credit Note related",
    questions: [
      {
        question:
          "Where are the details for the Credit Note that I received via email?",
        answer:
          "You may find details for the Credit note in the Annex to the Credit note. This can be downloaded from the Credit Note module of the portal",
      },
    ],
    isOpen: false,
  },
  {
    title: "Statement of Accounts related",
    questions: [
      {
        question: "Is there a yearly statement of accounts?",
        answer:
          "No, only monthly statement of accounts are available currently.",
      },
      {
        question: "How long are records kept for?",
        answer:
          "Information in the MAS Bill Portal is accessible by you for up to 7 years.",
      },
    ],
    isOpen: false,
  },
  {
    title: "Transaction History related",
    questions: [
      {
        question: "Can I download the transaction history?",
        answer:
          "No, however you may download relevant information from the Statement of Accounts Module.",
      },
      {
        question:
          "Why are there “missing transactions” in the Statement of Accounts compared to the Transaction History?",
        answer:
          "Statement of Accounts are generated on a monthly basis whereas Transaction History would include activities that occurred after the Statement of Account’s month end.",
      },
    ],
    isOpen: false,
  },
  {
    title: "Payment related",
    questions: [
      {
        question: "How to perform online payment?",
        answer: `You may scan the QR code on the invoice or remit the funds to MAS' bank account. Payment details can be found on the invoice. Alternatively, for more information, please visit our website at "<a href="https://www.mas.gov.sg/making-payments" target="_blank" rel="noopener noreferrer">Making Payments to MAS</a>”.`,
      },
      {
        question: "How to check if the automated deduction is successful?",
        answer: `You can refer to <ol type="1"><li>Transaction History to verify whether the automated deduction had occurred</li><li>View the Status in Invoice Module</li></ol>`,
      },
      {
        question: "How can I check the reason for failed GIRO deduction?",
        answer: `You can refer to the email notification on the reason for the failed GIRO deduction.`,
      },
      {
        question:
          "I have changed bank account, how do I apply for GIRO direct debit arrangement for my new bank account?",
        answer: `If you wish to make changes to your GIRO arrangement, please submit a new GIRO application form.<br /> For detailed instructions and GIRO application form, please visit <a href="https://www.mas.gov.sg/making-payments" target="_blank" rel="noopener noreferrer">https://www.mas.gov.sg/making-payments</a>`,
      },
    ],
    isOpen: false,
  },
  {
    title: "Other",
    questions: [
      {
        question:
          "How do I update the Company Particulars in the Account Settings?",
        answer: `Company Particulars are populated based on your past correspondences and submissions (e.g. Corporate License Application, Change of Name and/or Change of Company Particulars). To update your Company’s billing address, please go to Accounts Settings, select “Manage Company Address”, and make the necessary changes. For updates to other Company Particulars, please contact your assigned MAS officer and submit the required forms as applicable.`,
      },
    ],
    isOpen: false,
  },
]);

const isRedirectedFromAccountSettings = computed(
  () => route.query.fromAccountSettings === "true",
);

onMounted(() => {
  if (isRedirectedFromAccountSettings.value) {
    faqData.value[1].isOpen = true;
    faqData.value[7].isOpen = true;
  }
});
</script>

<style lang="scss">
.xb-collapse.card.faq-collapse {
  .card-content {
    .content {
      ol {
        margin-left: 1.4em;
        margin-top: 0em;

        li + li {
          margin-top: 0;
        }
      }
    }
  }

  .wrap-faq-content {
    .columns {
      margin: 0px;
    }

    .question {
      font-weight: 600;

      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .answer {
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &:first-child {
      .question {
        > div {
          padding-top: 0px;
        }
      }
    }
    &:last-child {
      .answer {
        > div {
          padding-bottom: 0px;
        }
      }
    }

    &:nth-child(even) {
      background-color: #f2f2f2;
      box-shadow: 0 0 0 20px #f2f2f2;

      &:not(:last-child) {
        clip-path: inset(0 -20px);
      }

      &:last-child {
        clip-path: inset(0px -20px -12px -20px);
      }
    }
  }
}
</style>
