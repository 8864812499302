import baseServiceNoHeader from "../../services/baseServiceNoHeader";
import apiConfig from "../../services/apiConfig";

export default {
  namespaced: true,
  state: {
    errormessage: "",
  },
  getters: {
    getErrorMessage: ({ errormessage }) => errormessage,
  },
  mutations: {
    setErrorMessage(state, value) {
      state.errormessage = value;
    },
  },
  actions: {
    fetchErrorMessage({ commit }, { payload, onError }) {
      return baseServiceNoHeader.post(
        apiConfig.configuration.getEndpointDetail,
        payload,
        (response) => commit("setErrorMessage", response.data.data.value),
        (err) => onError(err),
      );
    },
  },
};
