<template>
  <div>
    <XbaseHeader :show-profile="false" />
    <o-loading
      v-model:active="isLoading"
      class="xb-loading"
      :is-full-page="false"
      style="z-index: 999"
    />
    <main role="main">
      <div class="wrapper">
        <div class="content-container">
          <section v-show="!isLoading" class="section">
            <h1
              class="has-text-primary is-border-bottom"
              style="margin-bottom: 10px"
            >
              Welcome,
              <span v-if="selectCustomerData.userInfo !== null">
                {{ selectCustomerData.userInfo.fullName }} ({{
                  selectCustomerData.userInfo.uen
                }})
              </span>
            </h1>
            <br />

            <div class="columns">
              <div class="column">
                <h2 class="has-text-primary" style="margin-bottom: 0px">
                  Select your Profile
                </h2>
              </div>
            </div>
            <form @submit.prevent="submitSelectCustomer">
              <div
                v-if="selectCustomerData.tpCustomer.length !== 0"
                class="card"
              >
                <div class="card-content">
                  <div class="content">
                    <o-field class="xb-radio">
                      <o-radio
                        v-model="selectProfile"
                        name="selectProfile"
                        native-value="forClient"
                        required
                      >
                        For my client
                      </o-radio>
                    </o-field>
                    <o-field
                      v-show="selectProfile === 'forClient'"
                      class="xb-select"
                    >
                      <o-select
                        v-model="selectClient"
                        name="selectClient"
                        placeholder="Select Client"
                        :required="selectProfile === 'forClient'"
                      >
                        <option
                          v-for="option in selectCustomerData.tpCustomer"
                          :key="option"
                          :value="option.identificationNumber"
                        >
                          {{ option.customerName }} -
                          {{ option.identificationNumber }}
                        </option>
                      </o-select>
                    </o-field>
                  </div>
                </div>
              </div>
              <br />
              <div v-if="selectCustomerData.ownCustomer !== null" class="card">
                <div class="card-content">
                  <div class="content">
                    <o-field class="xb-radio">
                      <o-radio
                        v-model="selectProfile"
                        name="selectProfile"
                        native-value="forOwn"
                        required
                      >
                        For my own company
                      </o-radio>
                    </o-field>
                  </div>
                </div>
              </div>
              <br />
              <div class="columns">
                <div class="column">
                  <div class="buttons">
                    <button
                      type="submit"
                      class="xb-button button is-primary"
                      style="text-transform: none"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </div>
    </main>
    <XbaseFooter />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";
import XbaseFooter from "@/components/shared/xbase-footer/XbaseFooter.vue";

const route = useRoute();
const store = useStore();

const customerId = ref(null);
const selectProfile = ref("");
const selectClient = ref(null);

const isLoading = computed(() => store.getters["selectCustomer/isLoading"]);
const selectCustomerData = computed(
  () => store.getters["selectCustomer/getSelectCustomerData"],
);
const eic_state = computed(() => localStorage.getItem("eic_state"));

const submitSelectCustomer = async () => {
  if (selectProfile.value === "forOwn") {
    selectClient.value =
      selectCustomerData.value.ownCustomer.identificationNumber;
  }

  const payload = {
    userId: customerId.value,
    identificationNumber: selectClient.value,
    eic_state: eic_state.value,
  };

  await store.dispatch("selectCustomer/submitSelectCustomer", {
    payload,
  });
};

onMounted(async () => {
  customerId.value = route.query.UserID;
  await store.dispatch("selectCustomer/getSelectCustomerData", {
    payload: {
      userId: customerId.value,
      eic_state: eic_state.value,
    },
  });
});
</script>

<style lang="scss">
.columns {
  .box-container {
    border: 1px solid #f2f2f2;
  }
  .box-item {
    margin: 0 5px;
    &:last-child {
      margin-top: 5px;
      border-top: 1px solid #f2f2f2;
    }
  }
}
.is-border-bottom {
  border-bottom: 1px #8d7249 solid;
}
</style>
