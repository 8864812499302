<template>
  <div>
    <XbaseHeader :show-profile="false" />
    <main role="main">
      <o-loading
        v-model:active="isLoading"
        class="xb-loading"
        :is-full-page="true"
        style="z-index: 999"
      />
      <div class="wrapper">
        <div class="content-container maintenance">
          <div class="columns">
            <div class="column is-6 is-offset-1 maintenance-message">
              <h1 class="has-text-primary">
                {{ announcementData.message.header }}
              </h1>
              <p v-html="sanitizeHtml(announcementData.message.body)"></p>
            </div>
            <div class="column is-4 maintenance-message">
              <img
                src="@/assets/img/maintenance.svg"
                alt="Site under maintenance image"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import XbaseHeader from "@/components/shared/xbase-header/XbaseHeader.vue";
import { useErrorHandler } from "@/composables/useErrorHandler";
import { computed, onBeforeMount, watch } from "vue";
import sanitizeHtml from "sanitize-html";
import { useStore } from "vuex";

const store = useStore();
const { showErrorModal } = useErrorHandler();

const announcementData = computed(
  () => store.getters["announcementPage/getAnnouncementData"]
);

const isLoading = computed(() => store.getters["announcementPage/isLoading"]);

onBeforeMount(() => {
  store.dispatch("announcementPage/getAnnouncement", {
    payload: {
      key: "MasBa.Public.Internet.Announcement",
      valueType: "System.String",
    },
    onError: showErrorModal,
  });
});

watch(
  () => announcementData.value,
  () => {
    redirectionPage();
  },
  { deep: true }
);

function redirectionPage() {
  if (announcementData.value.autoRedirection.doAutoRedirection) {
    setTimeout(() => {
      window.location.href = announcementData.value.autoRedirection.url;
    }, announcementData.value.autoRedirection.intervalRedirection);
  }
}
</script>

<style lang="scss">
.content-container.maintenance {
  height: calc(100vh - 200px);
  display: flex;
  flex-flow: column nowrap;

  .maintenance-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .wrapper {
    min-height: calc(100vh - 300px);
  }
  .content-container.maintenance {
    height: calc(100vh - 300px);

    .columns {
      .maintenance-message {
        text-align: center;
        img {
          max-width: 80%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .maintenance {
    .columns {
      display: flex;
      flex-direction: column-reverse;

      .maintenance-message {
        align-items: center;

        img {
          height: auto;
          max-width: 50%;
        }
      }
    }
  }
}
</style>
