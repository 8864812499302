import baseServiceNoHeader from "../../services/baseServiceNoHeader";
import apiConfig from "../../services/apiConfig";

const footer = {
  namespaced: true,
  state: {
    lastUpdated: "-",
  },
  getters: {
    getLastUpdated: ({ lastUpdated }) => {
      return lastUpdated;
    },
  },
  mutations: {
    setLastUpdated(state, { value }) {
      const parseValue = value.replaceAll(`"`, "");
      const date = new Date(parseValue).toLocaleDateString("en-SG", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      state.lastUpdated = date;
    },
  },
  actions: {
    fetchLastUpdated({ commit }, { payload, onError }) {
      return baseServiceNoHeader.post(
        apiConfig.configuration.getEndpointDetail,
        {
          ...payload,
        },
        (response) => {
          commit("setLastUpdated", response.data.data);
        },
        (error) => onError(error),
      );
    },
  },
};

export default footer;
